export const S3_IMG = "https://operator-banner.s3.eu-west-2.amazonaws.com/pg-lite";

export const fetchUserIp = async () => {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error("Error fetching user IP:", error);
    return null;
  }
};

export const CProvId = "CR123"

export const loadColorConfig = (response: any) => {
  response.inputboxColor &&
    document.documentElement.style.setProperty(
      "--inputbox-color",
      `${response.inputboxColor}`
    );
  response.borderColor &&
    document.documentElement.style.setProperty(
      "--border-color",
      `${response.borderColor}`
    );
  response.meitmsColor &&
    document.documentElement.style.setProperty(
      "--meitms-color",
      `${response.meitmsColor}`
    );
  response.meicnbgColor &&
    document.documentElement.style.setProperty(
      "--meicnbg-color",
      `${response.meicnbgColor}`
    );
  response.appbgColor &&
    document.documentElement.style.setProperty(
      "--app-bg-color",
      `${response.appbgColor}`
    );
  response.headerColor &&
    document.documentElement.style.setProperty(
      "--header-color",
      `${response.headerColor}`
    );
  response.filterColor &&
    document.documentElement.style.setProperty(
      "--filter-color",
      `${response.filterColor}`
    );

};


export const checkTelegramWebApp = (): boolean => {
  if (window.Telegram && window.Telegram.WebApp) {
    console.log("Telegram WebApp is available.");
    return true;
  } else {
    console.error(
      "Telegram WebApp is not available. Make sure this is running inside Telegram."
    );
    return false;
  }
};

export const telegramWebApp = (): string => {
  if (window?.Telegram && window?.Telegram?.WebApp) {
    return "Telegram WebApp is available.";
  } else {
    return "Telegram WebApp is not available. Make sure this is running inside Telegram.";

  }
};

declare global {
  interface Window {
    Telegram: {
      WebApp: TelegramWebApp;
    };
  }
}

interface TelegramWebApp {
  initData: string;
  initDataUnsafe: {
    user?: {
      id: number;
      first_name: string;
      last_name?: string;
      username?: string;
      language_code?: string;
    };
  };
  ready(): void;
  showPopup(params: TelegramPopupParams): void;
  close(): void;
  onEvent(eventType: string, callback: (data: any) => void): void;
  requestContact(callback: (contact: TelegramWebAppUserContact | null) => void): void;
  version: string;
  requestFullscreen(): void;
  exitFullscreen(): void;
  ContentSafeAreaInset : {top: 10, bottom: 10, left: 10, right: 10}
}

interface TelegramPopupParams {
  title: string;
  message: string;
  buttons: Array<{
    // id: string;
    type: 'default' | 'ok' | 'close' | 'cancel' | 'destructive' | 'request_contact';
    text: string;
    // request_contact?: boolean;
  }>;
}

interface TelegramWebAppUserContact {
  phone_number: string;
  first_name: string;
  last_name?: string; // Optional
  user_id?: number;   // Optional Telegram user ID
}
