import React, { useEffect, useState } from "react";
import BalanceCard from "../BalanceCard/BalanceCard";
import API from "../../services";
import { UserDetails } from "../../models/UserDetails";
import { connect } from "react-redux";
import { RootState } from "../../store/store";
import Dialog from "@mui/material/Dialog";
import CloseIcon from '@mui/icons-material/Close';

interface TelegramUser {
  id: number;
  first_name: string;
  last_name?: string;
  username?: string;
}

interface WalletConnectProps {
  userDetails: UserDetails;
}

const WalletConnect: React.FC<WalletConnectProps> = ({ userDetails }) => {

  const [amount, setAmount] = useState<any>();
  const [testopen, setTestopen] = useState<any>(false);
  const inputBoxClass =
    "px-4 py-3 mt-1.5 w-full text-sm font-medium text-white placeholder:text-text-color rounded-lg border border-solid bg-inputbox-color border-border-color focus:outline-none leading-[1.4rem]";


  function requestUserContact() {

    try {


      window?.Telegram?.WebApp?.ready();

      window?.Telegram?.WebApp?.requestContact((contact) => {
        if (contact) {
          console.log("Contact received:", contact);
          window.location.reload();
        } else {
          console.log("User declined to share contact.");
        }
      });

    } catch (e) {
      console.log("xc", e)
    }
  }

  useEffect(() => {
    // Initialize the Telegram Web App
    if (window?.Telegram?.WebApp) {
      window?.Telegram.WebApp?.ready();

      // Listen for Web App Query results
      window?.Telegram?.WebApp?.onEvent("web_app_query_result", (result) => {
        console.log("Received query result:", result);

        if (result?.ok) {
          // Process the result received from the bot
          const responseData = JSON.parse(result?.result);
          alert(`Success: ${responseData?.message}`);
        } else {
          // Handle any error in the query response
          console.error("Error in query result:", result?.error);
          alert("An error occurred: " + result?.error_message);
        }
      });
    }
  }, []);

  const dummyApi = async (dum: any) => {

    let response: any;
    try {
      response = await API.post(`/debug/debug-info`, {
        info: dum
      });

      console.log("resp", response.data)
    } catch (e) {
      return;
    }


  };


  const handleSubmit = () => {
    if (!amount || isNaN(amount)) {
      alert("Please enter a valid amount.");
      return;
    }

    // Prepare the query data
    const queryData = {
      action: "deposit",
      amount: parseFloat(amount),
    };

    // Send the query to the bot
    if (window.Telegram?.WebApp) {
      console.log("queryData", queryData)
      window.Telegram.WebApp.sendData(JSON.stringify(queryData));
    } else {
      alert("Telegram WebApp is not initialized.");
    }
  };



  return (
    <>
      <div className="flex flex-col px-4 mt-4 w-full pb-[6rem] py-[4rem]">
        <div className="flex gap-5 justify-between font-medium">
          <div className="text-base text-white"
            // onClick={() => setTestopen(true)}
          >Wallet</div>

          {
            !userDetails?.MobileNumber ? (
              <button
                onClick={requestUserContact}
                className="border text-white rounded-lg px-[10px]"
              >
                Share Contact
              </button>
            ) : (
              <></>
            )
          }
        </div>
        <div className="mt-3 w-full aspect-[2.86]">
          <BalanceCard />
        </div>

      </div>
      {
        testopen && (
          <Dialog
            fullScreen={false}
            open={testopen}
            onClose={() => setTestopen(false)}
            PaperProps={{
              style: {
                position: 'absolute',
                top: '2rem'
              }
            }}
          >
            <div className="withdrawdialog-bg bg-inputbox-color relative p-4">
              <CloseIcon
                className="absolute top-3 right-3 text-white text-2xl font-semibold"
                onClick={() => setTestopen(false)}
              />
              <div>
                <div>
                  Enter Amount
                </div>
                <div>
                  <>
                    <label htmlFor="amount" className="block mt-3 text-sm text-zinc-400">
                      Amount
                    </label>
                    <input
                      type="number"
                      id="amount"
                      className={`${inputBoxClass} ${!userDetails?.MobileNumber ? "bg-gray-100 cursor-not-allowed" : ""
                        }`}
                      placeholder="Enter your amount"
                      value={amount}
                      onChange={(e) => {
                        const value = e.target.value === "" ? 0 : parseFloat(e.target.value);
                        setAmount(value);
                      }}
                      disabled={!userDetails?.MobileNumber}
                    />

                    <button
                      type="submit"
                      className={`p-2.5 mt-4 w-full text-sm text-white whitespace-nowrap bg-border-color rounded-lg border border-border-color border-solid leading-[1.4rem]
                        }`}
                      onClick={() => handleSubmit()}
                    >
                      Submit
                    </button>
                  </>
                </div>
              </div>
            </div>
          </Dialog>
        )
      }
    </>
  )
};

const mapStateToProps = (state: RootState) => {
  return {
    userDetails: state.userDetails?.userDetails,
  };
};

export default connect(mapStateToProps)(WalletConnect);
