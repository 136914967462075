import React from "react";
import ProfileImg from "./../../assets/images/profileImage.png";
import { connect } from "react-redux";
import { RootState } from "../../models/RootState";
import { UserDetails } from "../../models/UserDetails";


interface ProfileProps {
  userDetails: UserDetails;
  userThumbnail: any;
}

const ProfileInfo: React.FC<ProfileProps> = ({ userDetails, userThumbnail }) => {

  function requestUserContact() {

    try {

      console.log('Telegram WebApp version:', window?.Telegram?.WebApp?.version);

      window?.Telegram?.WebApp?.ready();

      window?.Telegram?.WebApp?.requestContact((contact) => {
        if (contact) {
          console.log("Contact received:", contact);
          window.location.reload();
        } else {
          console.log("User declined to share contact.");
        }
      });

    } catch (e) {
      console.log("xc", e)
    }
  }

  return (
    <section className="flex flex-col items-center">
      <img
        loading="lazy"
        src={userThumbnail === "" ? ProfileImg : userThumbnail}
        alt="Profile picture"
        className="object-contain mt-7 max-w-full aspect-square w-[100px]"
      />
      <h1 className="mt-3 text-sm text-center text-white">{userDetails?.UserName}</h1>
      <p className="mt-1 text-sm text-center text-zinc-400">{userDetails.EmailId}</p>
      {
        userDetails?.MobileNumber && userDetails?.MobileNumber === "" ?
          <>
            <button onClick={requestUserContact} className="border text-white rounded-lg px-[10px]">Share Contact Info</button>
          </> :
          <></>
      }
    </section>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    userDetails: state.userDetails?.userDetails,
    userThumbnail: state.userDetails?.Thumbnail,
  };
};

export default connect(mapStateToProps)(ProfileInfo);
