import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, NavLink, useParams } from "react-router-dom";
import API from "../../services";
import { S3_IMG } from "../../constants/common";
import "./ProviderGames.scss"
import { toast } from "react-toastify";
import { Autocomplete, TextField } from "@mui/material";
import { Error, FavoriteBorderOutlined, FavoriteOutlined, Search } from "@mui/icons-material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Loader from "../../assets/images/Strike_White_Logo_Transparent_GIF.gif";

const ProviderGames: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const prov = location.state?.prov;
    const [loading, setLoading] = useState<boolean>(false);
    const [gameData, setGameData] = useState<any>();
    const [bannersData, setBannersData] = useState<any>(null);
    const [searchQuery, setSearchQuery] = useState("");

    let filteredGames = gameData?.filter((game: any) =>
        game?.name?.toLowerCase().includes(searchQuery.toLowerCase())
    );




    const getProviderInfo = async () => {
        setLoading(true);

        let response;
        try {
            response = await API.post(`/webapp/get-games-by-provider`, {
                providerName: prov?.provider,
                subProviderName: prov?.subProvider,
                tgProviderId: prov?.tgProviderId

            });
        } catch (e) {
            console.log(e);
            setLoading(false);
            return;
        }

        if (response.data.status !== "RS_OK") {
            toast.error(response.data?.errorDescription);
            navigate("/casino");
        }

        if (response && response.data?.gamesList) {
            setGameData(response.data?.gamesList);
        }
        setLoading(false);
    };

    const handleGameClick = (game: any) => {

    }

    const handleFavoriteClick = async (game: any) => {

        setGameData((prevGameData: any) =>
            prevGameData.map((gameMap: any) =>
                gameMap?.id === game?.id
                    ? { ...gameMap, isFavorite: !gameMap?.isFavorite }
                    : gameMap
            )
        );
        let response;
        try {
            // Make the API call to update the favorite status
            response = await API.post('/webapp/set-favorite', {
                category: game.category,
                id: game.id,
                isFavorite: !game.isFavorite,
                name: game?.name,
                provider: game?.provider,
                subProvider: game.subProvider,
                thumbnail: game.thumbnail,
                type: game.type,
                tgProviderId: game?.tgProviderId,
                tableId: game?.tableId,
                userKey: sessionStorage.getItem("userKey")
            });

        } catch (error) {
            console.error('Error updating favorite status:', error);
            // Revert the state change if the API call fails
        }

        if(response && response.data?.status === "RS_OK"){
            getProviderInfo();
        }
    };



    useEffect(() => {
        getProviderInfo();
    }, []);

    return (
        <>
            {
                loading ? (
                    <div className="home-loading">
                        <img src={Loader} style={{ height: '10rem' }} />
                    </div>
                ) : (
                    <>
                        {/* <div className="flex flex-col px-6 mt-4 w-full pb-[7rem] py-[4rem] provinfo"> */}
                        <div className="flex items-center justify-between px-6 mt-4 w-full pt-[4rem] pb-4 sticky top-0 z-10 provinfo">
                            <div className="flex items-center">
                                <div>
                                    <div className="bg-inputbox-color flex p-1 mr-[1rem] rounded"
                                        onClick={() => navigate("/casino")}
                                    >
                                        <ArrowBackIcon
                                            className="text-border-color aspect-square w-[18px]"
                                            style={{ width: '20px', height: '20px' }}
                                        />
                                    </div>
                                </div>
                                <div className="">
                                    <img
                                        src={prov?.thumbnail}
                                        alt="back"
                                        loading="lazy"
                                        className="shrink-0 my-auto aspect-square w-[44px]"
                                    />
                                </div>
                            </div>
                            <div>
                                <input
                                    type="text"
                                    placeholder="Search games..."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    className="ml-4 p-2 border bg-inputbox-color border-border-color border rounded-md"
                                    style={{ width: "150px", height: '30px' }}
                                />
                            </div>
                        </div>
                        <div className="grid-cols-2 grid gap-2 px-6 w-full pb-[7rem] sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 provinfo">
                            {
                                filteredGames?.map((game: any) => (
                                    <>
                                        <div>
                                            <NavLink
                                                to={`/play/${btoa(`${game?.id}::${game?.tgProviderId}:${game?.provider}:${game?.subProvider}:${game?.type}:${game?.category}:${game?.tableId ? game?.tableId : ""}`)}`}
                                                className={`nav-link`}
                                            >
                                                <div
                                                    className="game-img relative"
                                                    style={{
                                                        marginBottom: "0.5rem",
                                                        height: "6.5rem",
                                                        // width: "10rem",
                                                    }}
                                                >
                                                    <img
                                                        src={game?.thumbnail}
                                                        alt={game?.name}
                                                        style={{
                                                            width: "100%",
                                                            height: "100%",
                                                            borderRadius: "12px",
                                                        }}
                                                    />
                                                    <div style={{ position: 'absolute', top: '5px', right: '5px' }}>
                                                        {game?.isFavorite ? <FavoriteOutlined
                                                            className="gold-favourite-img"
                                                            titleAccess={"remove from favourites"}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                handleFavoriteClick(game);
                                                            }}
                                                        /> : <FavoriteBorderOutlined
                                                            className="red-favourite-img"
                                                            titleAccess={"remove from favourites"}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                handleFavoriteClick(game);
                                                            }}
                                                        />}
                                                    </div>
                                                </div>
                                            </NavLink>
                                            <div className="game-name">{game?.name}</div>
                                            {/* <div className="prov-name">{game?.provider}</div> */}
                                        </div>
                                    </>
                                ))
                            }
                        </div>
                        {/* </div> */}
                    </>
                )
            }
        </>
    );
};

export default ProviderGames;

