import React from "react";

const NoAccessPage: React.FC = () => {
  return (
    <div className="flex justify-center items-center h-screen bg-black text-red-500">
      <h4>No Access, the url may be wrong</h4>
    </div>
  );
};

export default NoAccessPage;
