import { useEffect } from "react";
import API from "../services";
import { fetchUserIp } from "../constants/common";
import { toast } from "react-toastify";

function App() {
  const fetchSessionUrl = async (userIp: string) => {
    try {
      const encodedUrl = window.location.href;
      const pageUrl = decodeURIComponent(encodedUrl);
      const url = new URL(pageUrl);
      const params = new URLSearchParams(url.search);
      const id = params.get("id");
      const lobby = params.get("lobby");
      const response = await API.post(`/pgapi/getSessionUrl`, {
        url: pageUrl,
        providerName: lobby,
        id: id,
        clientIp: String(userIp),
      });

      const data = response.data;
      if (data.status !== "RS_OK") {
        console.log(
          "Failed to fetch session URL, error:",
          data.errorDescription
        );
        toast.error("Error While Loggin In")
        return;
      }
      // window.open(data.sessionUrl, "_self");
      window.location.href = data.sessionUrl;
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };


  const initialize = async () => {
    const userIp = await fetchUserIp();
    if (userIp) {
      // fetchSessionUrl(userIp);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <div className="flex items-center justify-center h-screen bg-inputbox-color text-white">
      <p className="text-lg font-medium">Please wait some time...</p>
    </div>
  );
}

export default App;
