import React, { useEffect, useState } from "react";
import { IonSpinner } from "@ionic/react";
import { useLocation, useNavigate, NavLink, useParams } from "react-router-dom";
import API from "../../services";
import './Home.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import 'swiper/css/pagination';
import { S3_IMG } from "../../constants/common";
import { Pagination } from 'swiper/modules';
import Home from "../../assets/images/Home.svg";
import { FavoriteOutlined, FavoriteBorderOutlined } from "@mui/icons-material";
import Loader from "../../assets/images/Strike_White_Logo_Transparent_GIF.gif"
import { RootState } from "../../models/RootState";
import { setBanners, setHomeData } from "../../store/authReducer";
import { connect } from "react-redux";
// import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteOutlined';

interface HomePageProps {
    homeData: any;
    banners: any;
    setHomeData: (data: any) => void;
    setBanners: (data: any) => void;
}

const HomePage: React.FC<HomePageProps> = ({ homeData, setHomeData, banners, setBanners }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [gameData, setGameData] = useState<any>();
    const [sectionData, setSectionData] = useState<any>(null);
    const [bannersData, setBannersData] = useState<any>(null);

    const sliderSettings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        swipeToSlide: true,
    };


    const getHomeInfo = async () => {
        setLoading(true);

        let response;
        try {
            response = await API.post(`/webapp/get-home-content`, {
            });
        } catch (e) {
            console.log(e);
            setLoading(false);
            return;
        }

        if (response.data.status !== "RS_OK") {
            navigate("/sports");
        }

        if (response && response.data.sections) {
            setGameData(response.data.sections);
            setHomeData(response.data.sections);
        }
        setLoading(false);
    };

    const handleFavoriteClick = async (game: any) => {
        setGameData((prevGameData: any) =>
            prevGameData?.map((section: any) => ({
                ...section,
                items: section?.items?.map((gameMap: any) =>
                    gameMap?.id === game?.id
                        ? { ...gameMap, isFavorite: !gameMap?.isFavorite }
                        : gameMap
                ),
            }))
        );
        let response;
        try {
            // Make the API call to update the favorite status
            response = await API.post('/webapp/set-favorite', {
                category: game.category,
                id: game.id,
                isFavorite: !game.isFavorite,
                name: game?.name,
                provider: game?.provider,
                subProvider: game.subProvider,
                thumbnail: game.thumbnail,
                type: game.type,
                tgProviderId: game?.tgProviderId,
                tableId: game?.tableId,
                userKey: sessionStorage.getItem("userKey")
            });

        } catch (error) {
            console.error('Error updating favorite status:', error);
            // Revert the state change if the API call fails
        }

        if(response && response.data?.status === "RS_OK"){
                const updatedGameData = gameData?.map((section: any) => ({
                    ...section,
                    items: section?.items?.map((gameMap: any) =>
                        gameMap?.id === game?.id
                            ? { ...gameMap, isFavorite: !gameMap?.isFavorite }
                            : gameMap
                    ),
                }));
            setHomeData(updatedGameData);
        }
    };

    const getBannerInfo = async () => {

        let response;
        try {
            response = await API.post(`/webapp/get-promotions`, {
            });
        } catch (e) {
            console.log(e);
            return;
        }
        if (response && response.data.promotions) {
            setBannersData(response.data.promotions);
            setBanners(response.data.promotions)
        }
    };

    const handleBannerClick = (banner: any) => {
        navigate('/bannerinfo', { state: { banner } });
    };

    useEffect(() => {
        if (homeData && homeData.length > 0) {
            setGameData(homeData);
        } else {
            getHomeInfo();
        }
        if (banners && banners.length > 0) {
            setBannersData(banners);
        } else {
            getBannerInfo();
        }
        sessionStorage.setItem("previousPath", location.pathname);
    }, []);

    return (
        <>
            <div className="flex flex-col px-3 mt-4 w-full pb-[7rem] py-[4rem] home">
                {
                    loading ? (
                        <div className="home-loading">
                            <img src={Loader} style={{ height: '10rem' }} />
                        </div>
                    ) : (
                        <>
                            {bannersData && (
                                <div className="banner-section">
                                    <Swiper
                                        spaceBetween={10}
                                        slidesPerView={1}
                                        breakpoints={{
                                            640: { slidesPerView: 2, spaceBetween: 10 },
                                            768: { slidesPerView: 3, spaceBetween: 20 },
                                            1024: { slidesPerView: 4, spaceBetween: 30 },
                                        }}
                                        loop={true}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        modules={[Pagination]}
                                    >
                                        {bannersData?.map((banner: any) => (
                                            <SwiperSlide key={banner?.promotionTitle}>
                                                <div style={{ height: '12rem' }}
                                                    onClick={() => handleBannerClick(banner)}
                                                >
                                                    <img
                                                        src={banner?.promotionImage}
                                                        alt="banner"
                                                        style={{ width: '100%', height: '100%', borderRadius: '12px' }}
                                                    />
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            )
                            }
                            <div className="main-section">
                                {gameData?.map((section: any, index: number) => (
                                    <div key={index} style={{ marginBottom: '10px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '0.75rem' }}>
                                            <div>
                                                <img src={section?.sectionIcon !== "" ? section?.sectionIcon : `${S3_IMG}/casino.svg`} alt={section?.sectionTitle} style={{ width: '1rem', marginRight: '5px' }} />
                                            </div>
                                            <span className="section-title">{section?.sectionTitle}</span>
                                        </div>
                                        <Swiper
                                            spaceBetween={10}
                                            slidesPerView={2.1}
                                            breakpoints={{
                                                640: { slidesPerView: 3.5, spaceBetween: 10 },
                                                768: { slidesPerView: 3.5, spaceBetween: 20 },
                                                1024: { slidesPerView: 4, spaceBetween: 30 },
                                            }}
                                        >
                                            {section?.items?.map((game: any, gameIndex: number) => (
                                                <SwiperSlide key={game?.id + gameIndex}>
                                                    <div style={{ position: 'relative' }}>
                                                        <NavLink
                                                            to={`/play/${btoa(`${game?.id}:${game?.id}:${game?.tgProviderId}:${game?.provider}:${game?.subProvider}:${game?.type}:${game?.category}:${game?.tableId ? game?.tableId : ""}`)}`}
                                                            className="nav-link"
                                                        >
                                                            <div className="game-img" style={{ marginBottom: '0.5rem', height: '7.5rem', width: '10rem', position: 'relative' }}>
                                                                <img src={game?.thumbnail} alt={game?.name} style={{ width: '100%', height: '100%', borderRadius: '12px' }} />
                                                                <div style={{ position: 'absolute', top: '5px', right: '5px' }}>
                                                                    {game?.isFavorite ? <FavoriteOutlined
                                                                        className="gold-favourite-img"
                                                                        titleAccess={"remove from favourites"}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            handleFavoriteClick(game);
                                                                        }}
                                                                    /> : <FavoriteBorderOutlined
                                                                        className="red-favourite-img"
                                                                        titleAccess={"remove from favourites"}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            handleFavoriteClick(game);
                                                                        }}
                                                                    />}
                                                                </div>
                                                            </div>
                                                        </NavLink>
                                                        <div className="flex items-center">
                                                            <p className="game-name">
                                                                {section?.sectionType?.toLowerCase() !== "sports" ? game?.name : game?.name}
                                                            </p>
                                                        </div>
                                                        <div className="prov-name">{game?.provider}</div>
                                                    </div>

                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </div>
                                ))}
                            </div>
                        </>
                    )
                }
            </div>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        homeData: state.userDetails?.homeData,
        banners: state.userDetails?.banners,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        setHomeData: (data: any) => {
            dispatch(setHomeData(data));
        },
        setBanners: (data: any) => {
            dispatch(setBanners(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);

