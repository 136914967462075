import React, { useEffect, useState } from "react";
import { IonSpinner } from "@ionic/react";
import { useLocation, useNavigate } from "react-router-dom";
import API from "../../services";

const IframePage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [gameSrc, setGameSrc] = useState<string | null>(null);

  const getGameUrl = async (name: string) => {
    setLoading(true);

    let response;
    try {
      response = await API.post(`/pgapi/get-login-url`, {
        userKey: sessionStorage.getItem("userKey"),
        providerName: name,
        // clientIp:""
      });
    } catch (e) {
      console.log(e);
      setLoading(false);
      return;
    }

    if (response.data.status !== "RS_OK") {
      navigate("/sports");
    }

    if (response && response.data.sessionUrl) {
      setGameSrc(response.data.sessionUrl);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (location.state && location.state.name) {
      getGameUrl(location.state.name);
    } else {
      navigate("/sports");
    }
  }, []);

  return (
    <div className="w-full h-[92vh] pt-[4rem] top-0 bottom-0 left-0 right-0 fixed">
      {gameSrc && (
        <iframe src={gameSrc} title="Game" allowFullScreen className="w-full h-full" sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups"/>
      )}
      {loading && (
        <div className="flex justify-center items-center min-h-[92vh]">
          <IonSpinner className="text-border-color" name="bubbles" />
        </div>
      )}
    </div>
  );
};

export default IframePage;
