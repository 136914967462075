import React, { useEffect, useState } from "react";
import gpayImage from "./../../assets/images/gpay.svg";
import phonepeImage from "./../../assets/images/phonepe.svg";
import paytmImage from "./../../assets/images/paytm.svg";
import { PaymentDetails } from "../../models/PaymentDetails";
import { toast } from "react-toastify";
import API from "../../services";
import { FaCopy } from "react-icons/fa";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
interface PaymentDialogProps {
  paymentProps: PaymentDetails;
  closeDialog: () => void;
}

const PaymentDialog: React.FC<PaymentDialogProps> = ({ paymentProps, closeDialog }) => {
  const [activeTab, setActiveTab] = useState<string>("upiPayment");
  const [loading, setLoading] = useState<boolean>(false);
  const [gpayLink, setGpayLink] = useState<string>("");
  const [phonepeLink, setPhonepeLink] = useState<string>("");
  const [paytmLink, setPaytmLink] = useState<string>("");
  const [utrNumber, setUtrNumber] = useState<string>("");

  useEffect(() => {
    setGpayLink(`tez://upi/${paymentProps.upiIntent}`);
    setPhonepeLink(`phonepe://${paymentProps.upiIntent}`);
    setPaytmLink(`paytmmp://${paymentProps.upiIntent}`);

    if (activeTab === "upiPayment" && !paymentProps.isUpi) {
      setActiveTab("bankTransfer");
    }
  }, [paymentProps]);

  const handleSubmit = async (depositType: string) => {
    setLoading(true);

    try {
      const response = await API.post(`/account/processDeposit`, {
        uKey: sessionStorage.getItem("userKey"),
        depositType: depositType,
        bankId: paymentProps.bankId,
        refId: paymentProps.referenceId,
        utr: utrNumber,
        tgProviderId: "PG123",
      });

      const data = await response.data;
      if (data.status !== "RS_OK") {
        toast.error(data.errorDescription);
      } else {
        closeDialog();
        toast.success("transaction saved, please switch wallet to Points");
      }
    } catch (error) {
      console.log("There was a problem with the fetch operation.");
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = (text: any) => {
    navigator.clipboard.writeText(text).then(() => {
    });
  }

  const handleLinkClick = (
    event: { preventDefault: () => void },
    link: string
  ) => {
    event?.preventDefault();
    // Redirect to the link
    window.open(link, "_blank");
  };

  const openUPIApps = (prefix?: string) => {
    let paymentWindow: Window | null = null;

    // console.log("sdfghj",  `${prefix}${paymentProps.upiIntent}`)
    if (prefix) {
      paymentWindow = window.open(
        `${prefix}${paymentProps.upiIntent}`,
        "_blank"
      );
    } else {
      // eslint-disable-next-line no-unused-vars
      paymentWindow = window.open(paymentProps.upiIntent, "cpgFrame");
    }
  };

  const renderUpiPaymentLinks = () => (
    <div className="flex justify-between items-center my-4">
      <div id="gpayLink" onClick={(e) => openUPIApps("tez://upi/")}>
        <img className="h-[60px] w-[60px]" src={gpayImage} alt="GPay" />
      </div>
      <div id="phonepeLink" onClick={(e) => openUPIApps("phonepe://")}>
        <img className="h-[60px] w-[85px]" src={phonepeImage} alt="PhonePe" />
      </div>
      <div id="paytmLink" onClick={(e) => openUPIApps("paytmmp://")}>
        <img className="h-[60px] w-[60px]" src={paytmImage} alt="Paytm" />
      </div>
    </div>
  );

  const renderPaymentForm = (depositType: string) => (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(depositType);
        }}
        className="mt-4 w-full max-w-md mx-auto"
      >
        <div className="mb-4">
          <label htmlFor="utrInput" className="block mt-3 text-sm text-zinc-400">
            UTR Number
          </label>
          <input
            type="text"
            className="px-4 py-3 mt-2.5 w-full text-sm font-medium text-white placeholder:text-text-color whitespace-nowrap rounded-lg border border-solid bg-inputbox-color border-border-color focus:outline-none leading-[1.4rem]"
            id="utrInput"
            placeholder="Enter UTR Number"
            onChange={(e) => setUtrNumber(e.target.value)}
          />
        </div>
        <div className="flex gap-2">
          <button
            type="button"
            className="p-2.5 w-full text-sm text-white whitespace-nowrap bg-red-600 rounded-lg border border-red-600 border-solid leading-[1.4rem]"
            onClick={closeDialog}
          >
            Cancel
          </button>
          <button
            type="submit"
            className={`p-2.5 w-full text-sm text-white whitespace-nowrap bg-border-color rounded-lg border border-border-color border-solid leading-[1.4rem] ${loading ? "opacity-50" : ""
              }`}
            disabled={loading}
          >
            <span className="submit-label">
              {loading ? "Processing..." : "Submit"}
            </span>
          </button>
        </div>
      </form>
      <div className="h-[200px] p-4">
      </div>
    </>
  );

  const renderActiveTabContent = () => {
    if (activeTab === "upiPayment") {
      return (
        <div id="upiPayment">
          {
            paymentProps?.upiIntent !== "" ? renderUpiPaymentLinks() : <></>
          }

          <div className="flex flex-col items-center text-white">
            <p className="text-center text-sm">
              Scan the QR code using any UPI application
            </p>
            <p className="text-center text-sm">
              किसी भी UPI एप्लिकेशन का उपयोग करके QR कोड को स्कैन करें
            </p>
            <div className="m-3 flex justify-center">
              <img
                id="imageDisplay"
                alt="QR Code"
                className="w-4/5 h-4/5"
                src={paymentProps.qrCode || ""}
              />
            </div>
            {paymentProps.upiId !== "" ? (
              <div className="flex items-center gap-2">
                <span>UPI Id:  {paymentProps.upiId}</span>
                <ContentCopyIcon
                  className="cursor-pointer hover:text-gray-800"
                  onClick={() => copyToClipboard(paymentProps.upiId)}
                // title="Copy to clipboard"
                />
              </div>
            ) : (
              <></>
            )}
            <p className="text-center text-sm">
              भुगतान के बाद नीचे दिए गए बटन पर क्लिक करें
            </p>
          </div>
          {renderPaymentForm("UPI")}
        </div>
      );
    }

    return (
      <div id="bankTransfer">
        <div className="leading-10 text-white">
          <p id="actId">Account ID: {paymentProps.actId}</p>
          <p id="actName">Account Name: {paymentProps.actName}</p>
          <p id="ifsc">IFSC: {paymentProps.ifsc}</p>
          <p id="bankId">Bank ID: {paymentProps.bankId}</p>
          <p id="bankName">Bank Name: {paymentProps.bankName}</p>
        </div>
        {renderPaymentForm("BANK_TRANSFER")}
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center p-4">
      <nav className="flex gap-5 justify-between self-center w-full text-sm text-center">
        <div
          id="upiTab"
          className={`flex-1 flex flex-col px-2 ${activeTab === "upiPayment"
            ? "font-bold text-white"
            : "text-zinc-400"
            } cursor-pointer ${!paymentProps.isUpi ? "opacity-50 cursor-not-allowed" : ""
            }`}
          onClick={() => paymentProps.isUpi && setActiveTab("upiPayment")}
        >
          <div>UPI</div>
          {activeTab === "upiPayment" && (
            <div className="shrink-0 mt-3 h-0.5 bg-border-color rounded-lg" />
          )}
        </div>
        <div
          id="bankTransferTab"
          className={`flex-1 flex flex-col px-2 ${activeTab === "bankTransfer"
            ? "font-bold text-white"
            : "text-zinc-400"
            } cursor-pointer ${!paymentProps.isBankTransfer ? "opacity-50 cursor-not-allowed" : ""
            }`}
          onClick={() =>
            paymentProps.isBankTransfer && setActiveTab("bankTransfer")
          }
        >
          <div>BANK TRANSFER</div>
          {activeTab === "bankTransfer" && (
            <div className="shrink-0 mt-3 h-0.5 bg-border-color rounded-lg" />
          )}
        </div>
      </nav>
      <div className="flex items-center justify-center">
        <div className="min-h-[30rem] min-w-[17rem]">
          {renderActiveTabContent()}
        </div>
      </div>
    </div>
  );
};

export default PaymentDialog;
