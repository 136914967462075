import React, { useState } from 'react';
import DownArrow from './../../assets/images/down-arrow.svg';

interface CurrencySelectorProps {
  currency: string;
  curList: string[];
}

const CurrencySelector: React.FC<CurrencySelectorProps> = ({ currency, curList }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(currency);

  const currencies = ['INR','USD', 'EUR', 'GBP', 'JPY'];

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleCurrencySelect = (curr: string) => {
    setSelectedCurrency(curr);
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <button
        // onClick={toggleDropdown}
        className="flex items-center justify-between px-3 py-2 w-full text-xs font-medium text-white whitespace-nowrap rounded-lg border border-solid bg-inputbox-color border-slate-800"
      >
        <span>{selectedCurrency}</span>
        {/* <img
          loading="lazy"
          src={DownArrow}
          alt=""
          className="shrink-0 ml-8 w-2.5 aspect-[1.67]"
        /> */}
      </button>
      {isOpen && (
        <ul className="absolute z-10 w-full mt-1 bg-inputbox-color border border-slate-800 rounded-lg shadow-lg">
          {curList?.map((curr) => (
            <li
              key={curr}
              onClick={() => handleCurrencySelect(curr)}
              className="px-3 py-2 text-xs text-white hover:bg-zinc-700 cursor-pointer"
            >
              {curr}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CurrencySelector;