import React, { useEffect, useRef, useState } from 'react';
import { NavigateBeforeRounded, NavigateNextRounded } from '@mui/icons-material';

type PaginationProps = {
    currentPage: number;
    eventsCount: number;
    setRowsPerPage: Function;
    setCurrentPage: Function;
    rowsPerPage: number;
    callBackFunc: Function;
}

const Pagination: React.FC<PaginationProps> = (props) => {
    const { currentPage, eventsCount, setCurrentPage, setRowsPerPage, rowsPerPage, callBackFunc } = props;
    const curList = [10, 20, 50, 100];
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => setIsOpen(!isOpen);


    const handleCurrencySelect = (curr: any) => {
        setRowsPerPage(curr)
        setIsOpen(false);
    };

    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
        } else {
            callBackFunc();
        }
    }, [currentPage, rowsPerPage]);

    useEffect(() => {
        setCurrentPage(1);
    }, [rowsPerPage])

    return (
        <>
            <div className="flex items-center justify-between">
                <div className='flex items-center'>
                    <div className='text-sm'>rows per Page: </div>
                    <div className="relative h-[30px]">
                        <button
                            onClick={toggleDropdown}
                            className="flex items-center h-full justify-between px-3 py-2 text-sm font-medium text-white whitespace-nowrap rounded-lg"
                        >
                            <span>{rowsPerPage}</span>
                        </button>

                        {isOpen && (
                            <ul className="absolute z-10 bottom-full mb-1 bg-inputbox-color border border-slate-800 rounded-lg shadow-lg w-full">
                                {curList?.map((curr) => (
                                    <li
                                        key={curr}
                                        onClick={() => handleCurrencySelect(curr)}
                                        className="px-3 py-2 text-xs text-white hover:bg-zinc-700 cursor-pointer"
                                    >
                                        {curr}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
                <div>
                    <div
                        className={`pagination-btn`}
                        onClick={() => setCurrentPage(currentPage - 1)}
                    >{
                            currentPage !== 1 ? (
                                <NavigateBeforeRounded />
                            ) : <></>
                        }
                    </div>
                </div>
                <div className={`text-sm pagination-pages`}>
                    {currentPage === 1 ? currentPage : currentPage * rowsPerPage - rowsPerPage + 1} - {currentPage * rowsPerPage > eventsCount ? eventsCount : currentPage * rowsPerPage} of {eventsCount}
                </div>
                <div className='flex'>
                    <div>
                        <div
                            className={`pagination-btn`}
                            onClick={() => setCurrentPage(currentPage + 1)}
                        >
                            {
                                (currentPage * rowsPerPage) < eventsCount ?
                                    <NavigateNextRounded /> : <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Pagination;