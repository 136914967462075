import React, { useEffect, useState } from "react";
import { IonSpinner } from "@ionic/react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { CancelOutlined } from "@mui/icons-material";
import { useDrag } from '@use-gesture/react';
import { useSpring, animated } from '@react-spring/web';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import "./FAQ.scss"
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LogoutIcon from '@mui/icons-material/Logout';


const FAQ: React.FC = () => {






  const navigate = useNavigate();

  const handleBackClick = () => {
    const path = sessionStorage.getItem("previousPath");
    if (path) {
      navigate(path);
    } else {
      navigate("/home");
    }
  };








  return (
    <>
      <div className="flex flex-col px-3 mt-4 w-full pb-[7rem] py-[4rem] text-white faq">
        <h1 className="text-xl font-medium mb-4">Frequently Asked Questions (FAQ)</h1>
        <span className="text-lg">1. What is
          Stike?</span>
        <p className="text-gray-400 font-medium">Strike is an online gaming and sportsbook platform accessible
          via Telegram.</p>
        <span className="text-lg">2. What games does Strike offer?</span>
        <p className="text-gray-400 font-medium">Strike offers a wide range of casino games including slots, table games, live
          casino, and sports betting options.</p>
        <span className="text-lg">3. Can I play with cryptocurrencies?</span>
        <p className="text-gray-400 font-medium">Currently, Strike supports playing with USDT, more currencies
          coming soon.</p>
        <span className="text-lg">4. How do Playing with Chips works ?</span>
        <p className="text-gray-400 font-medium">PlayChips are provided to encourage you to play.
          Only The profit from the PlayChips would be converted to points and The bets placed through the
          PlayChips would not come under Wager.</p>
        <span className="text-lg">5. How do I deposit funds into my Strike account?</span>
        <p className="text-gray-400 font-medium">To deposit funds, simply go to the Wallet Page and then to the deposit
          tab, now here you can either deposit to the Bank Account or simply copy the wallet address and transfer your
          crypto amount to that address.
        </p>
        <span className="text-lg">6. How do I withdraw my winnings from Strike?</span>
        <p className="text-gray-400 font-medium">Withdrawals can be made by submitting a withdrawal request in the Wallet Page
          Add your Bank Account and later request the Amount to withdraw.
          <br />
          Similarly
          if you play with Crypto Currencies.
          Your winnings will then be transferred to your designated wallet address. You can change
          your withdrawal address every time you do a withdrawal.
          <br />
          Note: Withdrawal condition
          <br />
          Your cumulative turnover should be 5 times the withdrawal amount , in order to make a successful withdrawal
          Example : In order to withdraw 200 , you need to make a total turnover of 1000 points.
        </p> <span className="text-lg">7. Are there any fees for
          depositing or withdrawing funds?</span>
        <p className="text-gray-400 font-medium">Strike does not charge any fees for deposits or withdrawals.</p>
        <span className="text-lg">8. Is there a minimum
          withdrawal amount?</span>
        <p className="text-gray-400 font-medium">Minimum withdrawal is 200 points.</p>
        <span className="text-lg">9. How can I
          contact customer support?</span>
        <p className="text-gray-400 font-medium">For any inquiries or assistance, you can contact our customer support team
          through Our Customer Support feature in the App.</p>
      </div>
    </>
  );
};

export default FAQ;
