import React, { useEffect, useState, useCallback, useRef } from "react";
import API from "../../../services";
import { toast } from "react-toastify";
import "./AccountStatement.scss";
import Loader from "../../../assets/images/Strike_White_Logo_Transparent_GIF.gif";
import { useNavigate } from "react-router";
import Pagination from "../Pagination";

interface CustomerSupportProps {

}



const AccountStatement: React.FC<CustomerSupportProps> = ({ }) => {


    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [tickets, setTickets] = useState<any>([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const navigate = useNavigate();
    const [count, setCount] = useState<any>(0);
    const [page1, setPage1] = useState(1);



    const dateFormat = (time: any) => {
        const matchStartTime = new Date(time);

        const formattedStartTime = matchStartTime.toLocaleString("en-GB", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: false,
        });

        return formattedStartTime
    }


    const isFirstRender = useRef(true);


    const getAccountStatement = async () => {
        if (isFirstRender.current) {
            setLoading(true);
            isFirstRender.current = false;
        }

        let response;
        try {
            response = await API.post(`/users/account-statement`, {
                page: page1,
                pageSize: rowsPerPage,
            });
        } catch (e) {
            console.log(e);
            setLoading(false);
            return;
        }

        if (response.data.status !== "RS_OK") {
            navigate("/home");
        }

        if (response && response.data?.ledgers) {
            setTickets(response?.data?.ledgers);
            if (page1 === 0 || page1 === 1) {
                setCount(response?.data?.total);
            }
        }
        setLoading(false);
    };


    useEffect(() => {
        getAccountStatement();
    }, [])




    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
        getAccountStatement();
    };




    return (
        <>
            <div className="flex flex-col px-3 mt-4 w-full pb-[7rem] py-[4rem] AccStatement">
                {
                    loading ? (
                        <div className="home-loading">
                            <img src={Loader} style={{ height: '10rem' }} />
                        </div>
                    ) : (
                        <>
                            <header className="flex gap-3 items-center self-start text-base font-medium text-center text-white mb-[0.5rem] w-full justify-between">
                                <h2 className="self-stretch my-auto">Account Statement</h2>
                            </header>
                            <div className="flex justify-center mb-2">
                                <div className="mr-16">
                                    <span className="inline-block w-2 h-2 bg-blue-500 rounded-full mr-2"></span>Points
                                </div>
                                <div>
                                    <span className="inline-block w-2 h-2 bg-yellow-500 rounded-full mr-2"></span>Play Chips
                                </div>
                            </div>
                            <div className="overflow-x-auto">
                                <table className="min-w-full border-collapse user-table border-x mt-1">
                                    <thead>
                                        <tr>
                                            <th className="px-4 py-2 text-left border-b border-t">Date</th>
                                            <th className="px-4 py-2 text-left border-b border-t">Type</th>
                                            <th className="px-4 py-2 text-left border-b border-t">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tickets.map((row: any, index: number) => (
                                            <tr key={index} className="border-b"
                                            >
                                                <td className="px-2 py-2">
                                                    {dateFormat(row?.TransactionTime)}
                                                </td>
                                                <td className="px-2 py-2">
                                                    {row?.TransactionType
                                                        ?.split('_')
                                                        .map((word: any) => word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase())
                                                        .join(' ')}
                                                </td>
                                                <td
                                                    className={`p-2 ${row?.Points > 0 || row?.PlayChips > 0
                                                        ? 'text-green-500'
                                                        : row?.Points < 0 || row?.PlayChips < 0
                                                            ? 'text-red-500'
                                                            : 'text-white'
                                                        }`}
                                                >
                                                    {row?.Points !== 0 ? (
                                                        <div className="flex items-center">
                                                            <span className="inline-block w-2 h-2 bg-blue-500 rounded-full mr-2"></span>
                                                            {row?.Points % 1 === 0 ? row?.Points : row?.Points.toFixed(2)}
                                                        </div>
                                                    ) : row?.PlayChips !== 0 ? (
                                                        <div className="flex items-center">
                                                            <span className="inline-block w-2 h-2 bg-yellow-500 rounded-full mr-2"></span>
                                                            {row?.PlayChips % 1 === 0
                                                                ? row?.PlayChips
                                                                : row?.PlayChips.toFixed(2)}
                                                        </div>
                                                    ) : (
                                                        0
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>
                            <Pagination currentPage={page1} setCurrentPage={setPage1} setRowsPerPage={setRowsPerPage} rowsPerPage={rowsPerPage} eventsCount={count} callBackFunc={getAccountStatement} />
                        </>
                    )
                }
            </div>
        </>
    );
};



export default AccountStatement;
