import React from "react";

interface OptionComponentProps {
  icon: string;
  title: string;
  description: string;
  onClick?: () => void;
}

const OptionComponent: React.FC<OptionComponentProps> = ({
  icon,
  title,
  description,
  onClick,
}) => {
  return (
    <div
      className={`flex flex-col justify-center items-start p-4 mt-3 max-w-full rounded-lg w-[92%] bg-meitms-color`}
      onClick={onClick}
    >
      <div className="flex gap-3 items-center">
        <div className="flex gap-2.5 items-center self-stretch p-2.5 my-auto w-11 h-11 rounded bg-meicnbg-color">
          <img
            loading="lazy"
            src={icon}
            alt={title}
            className="object-contain w-6 aspect-square"
            style={{
              filter:
                title === "FAQ"
                  ? "invert(100%) sepia(0%) saturate(0%) hue-rotate(7deg) brightness(102%) contrast(106%)"
                  : "",
            }}
          />
        </div>
        <div className="flex flex-col self-stretch my-auto w-[225px]">
          <h2 className="text-sm text-white">{title}</h2>
          <p className="mt-1.5 text-xs text-zinc-400">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default OptionComponent;
