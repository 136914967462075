import React, { useState } from "react";
import ProfileImg from "../../../assets/images/profileImage.png";
import { connect } from "react-redux";
import { RootState } from "../../../models/RootState";
import { UserDetails } from "../../../models/UserDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import "./MyProfile.scss"
import CloseIcon from '@mui/icons-material/Close';
import Dialog from "@mui/material/Dialog";
import { loadColorConfig } from "../../../constants/common";
import { toast } from "react-toastify";
import API from "../../../services";
import { setUserThumbnail, setUserName } from "../../../store/authReducer";
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';


export const themes = {
    blue: {
        inputboxColor: '#1C3234',
        borderColor: '#21ACB9',
        meitmsColor: '#21ACB950',
        meicnbgColor: '#21ACB930',
        appbgColor: '#051314',
        headerColor: '#2E4C4E',
        filterColor: 'invert(53%) sepia(92%) saturate(369%) hue-rotate(137deg) brightness(89%) contrast(93%)',
    },
    purple: {
        inputboxColor: '#272733',
        borderColor: '#B64FF6',
        meitmsColor: '#B64FF650',
        meicnbgColor: '#B64FF630',
        appbgColor: '#190C21',
        headerColor: '#412553',
        filterColor: 'invert(53%) sepia(74%) saturate(6142%) hue-rotate(255deg) brightness(102%) contrast(93%)',
    },
    green: {
        inputboxColor: '#25392E',
        borderColor: '#59DF91',
        meitmsColor: '#59DF9150',
        meicnbgColor: '#59DF9130',
        appbgColor: '#08160E',
        headerColor: '#294A37',
        filterColor: 'invert(67%) sepia(90%) saturate(276%) hue-rotate(90deg) brightness(97%) contrast(88%)',
    },
    yellow: {
        inputboxColor: '#28261A',
        borderColor: '#B29B35',
        meitmsColor: '#B29B3550',
        meicnbgColor: '#B29B3530',
        appbgColor: '#0E0D04',
        headerColor: '#3F3C20',
        filterColor: 'invert(62%) sepia(30%) saturate(928%) hue-rotate(12deg) brightness(95%) contrast(81%)',
    },
    orange: {
        inputboxColor: '#3C1F18',
        borderColor: '#FF6843',
        meitmsColor: '#FF684350',
        meicnbgColor: '#FF684330',
        appbgColor: '#0E0604',
        headerColor: '#503129',
        filterColor: 'invert(48%) sepia(47%) saturate(1188%) hue-rotate(329deg) brightness(102%) contrast(101%)',
    },
};

type ProfileProps = {
    userDetails: UserDetails;
    userThumbnail: any;
    setUserThumbnail: (data: any) => void;
    setUserName: (data: any) => void;
}

const MyProfile: React.FC<ProfileProps> = ({ userDetails, userThumbnail, setUserThumbnail, setUserName }) => {

    const navigate = useNavigate();
    const [showAvatars, setShowAvatars] = useState<any>(false);
    const [selectedImg, setSelectedImg] = useState<any>("1");
    const [nickName, setNickName] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [openName, setOpenName] = useState<any>(false);

    const getAssetImage = (imageName: any) => {
        return require(`../../../assets/images/av${imageName}.png`).default;
    };

    const images = ["1", "2", "3", "4", "5", "6", "7", "27", "28", "29", "30", "31", "32", "33", "57", "58", "59", "60"];


    const handleThemeChange = (themeName: keyof typeof themes) => {
        const selectedTheme = themes[themeName];

        if (selectedTheme) {
            changeTheme(themeName, selectedTheme);
        } else {
            console.error('Theme not found');
        }
    };

    const changeTheme = async (theme: any, selectedTheme: any) => {
        let response: any;
        try {
            response = await API.post(`/users/change-user-theme`, {
                theme: theme
            });
        } catch (e) {
            return;
        }
        if (response.data?.status !== "RS_OK") {
            toast.error(response.data?.errorDescription);
        } else {
            toast.success("Theme Changed Successfully")
            loadColorConfig(selectedTheme);
        }
    }

    const onChangeProfile = () => {
        let profile = `https://cdn.dreamdelhi.com/strike/av${selectedImg}.png`;
        changeProfilePic(profile);
    }


    const changeProfilePic = async (profile: any) => {
        let response: any;
        try {
            response = await API.post(`/users/change-user-thumbnail`, {
                thumbnail: profile
            });
        } catch (e) {
            return;
        }
        if (response.data?.status !== "RS_OK") {
            toast.error(response.data?.errorDescription);
        } else {
            setUserThumbnail(profile);
            toast.success("Avatar Changed Successfully");
            setShowAvatars(false);
        }
    }

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = await API.post(`/users/change-user`, {
                userName: nickName,
            });

            const data = await response.data;
            if (data?.status !== "RS_OK") {
                toast.error(data?.errorDescription);
            } else {
                setUserName(nickName);
                toast.success("Name Changed Successfully");
            }
            setOpenName(false);
        } catch (error) {
            console.log("There was a problem with the fetch operation.");
        } finally {
            setLoading(false);
        }
    };

    const handleBackClick = () => {
        navigate(-1);
    };
    return (
        <>
            <div className="flex flex-col items-center px-3 mt-4 w-full pb-[7rem] py-[4rem] my-profile">
                <header className="flex gap-3 items-center self-start text-base font-medium text-center text-white mb-[0.5rem]">
                    <FontAwesomeIcon
                        icon={faChevronLeft}
                        onClick={handleBackClick}
                        className="cursor-pointer object-contain shrink-0 self-stretch my-auto w-[12px] aspect-[0.57]"
                    />
                    <h2 className="self-stretch my-auto">My Profile</h2>
                </header>
                <img
                    loading="lazy"
                    src={userThumbnail === "" ? ProfileImg : userThumbnail}
                    alt="Profile picture"
                    className="object-contain flex items-center max-w-full aspect-square w-[100px]"
                />

                <div className="border border-solid text-sm bg-inputbox-color border-border-color m-1 py-[2px] px-[5px] rounded-2xl"
                    onClick={() => setShowAvatars(true)}
                >
                    Change Avatar
                </div>
                <div className="flex">
                    <h1 className="mt-3 text-base text-center text-white">{userDetails?.UserName}</h1>
                    <CreateRoundedIcon className="mt-3 ml-2" 
                    onClick={() => setOpenName(true)} />
                </div>
                <p className="mt-1 text-sm text-center text-zinc-400">{userDetails.MobileNumber}</p>
                <p className="mt-1 text-sm text-center text-zinc-400">{userDetails.EmailId}</p>
                <div className="mt-4">Change Theme</div>
                <div className="grid w-full grid-cols-3 grid gap-2 mt-2 text-sm">
                    <button
                        onClick={() => handleThemeChange('blue')}
                        className="p-2 py-1 rounded-full bg-[#1C3234] text-text-color border border-[#21ACB9]"
                    >
                        Blue
                    </button>
                    <button
                        onClick={() => handleThemeChange('purple')}
                        className="p-2 py-1 rounded-full bg-[#272733] text-text-color border border-[#B64FF6]"
                    >
                        Purple
                    </button>
                    <button
                        onClick={() => handleThemeChange('green')}
                        className="p-2 py-1 rounded-full bg-[#25392E] text-text-color border border-[#59DF91]"
                    >
                        Green
                    </button>
                </div>
                <div className="grid w-8/12 grid-cols-2 grid gap-2 mt-2 text-sm">
                    <button
                        onClick={() => handleThemeChange('yellow')}
                        className="p-2 py-1 rounded-full bg-[#28261A] text-text-color border border-[#B29B35]"
                    >
                        Yellow
                    </button>
                    <button
                        onClick={() => handleThemeChange('orange')}
                        className="p-2 py-1 rounded-full bg-[#3C1F18] text-text-color border border-[#FF6843]"
                    >
                        Orange
                    </button>
                </div>
            </div >

            {
                showAvatars && (
                    <Dialog
                        fullScreen={false}
                        open={showAvatars}
                        onClose={() => setShowAvatars(false)}
                        PaperProps={{
                            style: {
                                position: 'absolute',
                                top: '5rem'
                            }
                        }}
                        className="my-profile"
                    >
                        <div className="dialog-bg bg-inputbox-color relative p-4">
                            <div className="flex items-center justify-between pb-2">
                                <div className="text-xl">
                                    Choose Avatar
                                </div>
                                <CloseIcon
                                    className="absolute top-3 right-3 text-white text-2xl font-semibold"
                                    onClick={() => setShowAvatars(false)}
                                />
                            </div>
                            <div className="dg-main">
                                <div className="av-ctn">
                                    {images.map((imageName, index) => (
                                        <div key={index} className={`image-container rounded-full ${selectedImg === imageName ? "bg-border-color" : ""}`}
                                            onClick={() => setSelectedImg(imageName)}
                                        >
                                            <img
                                                // src={`../../../assets/images/av${imageName}.png`}
                                                src={`https://cdn.dreamdelhi.com/strike/av${imageName}.png`}
                                                alt={`Image ${index + 1}`}
                                                className="w-full h-auto object-cover rounded-lg"
                                                decoding="async"
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="flex justify-around mt-4 pt-2">
                                <button
                                    type="button"
                                    className="p-2 w-[30%] text-sm text-white bg-border-color rounded-lg border border-border-color border-solid leading-[1.4rem]"
                                    onClick={onChangeProfile}
                                >
                                    {"Save"}
                                </button>
                                <button
                                    type="button"
                                    className="p-2 w-[30%] text-sm text-white bg-error-color rounded-lg border border-error-color border-solid leading-[1.4rem]"
                                    onClick={() => setShowAvatars(false)}
                                >
                                    {"Cancel"}
                                </button>
                            </div>
                        </div>
                    </Dialog>
                )
            }

            {
                openName && (
                    <Dialog
                        fullScreen={false}
                        open={openName}
                        onClose={() => setOpenName(false)}
                        PaperProps={{
                            style: {
                                position: 'absolute',
                                top: '5rem'
                            }
                        }}
                    >
                        <div className="dialog-bg bg-inputbox-color relative p-4">
                            <CloseIcon
                                className="absolute top-3 right-3 text-white text-2xl font-semibold"
                                onClick={() => setOpenName(false)}
                            />
                            <div>
                                <div>
                                    Change User Name
                                </div>
                                <div>
                                    <>
                                        <form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                handleSubmit();
                                            }}
                                            className="mt-4 w-full max-w-md mx-auto"
                                        >
                                            <div className="mb-4">
                                                <input
                                                    type="text"
                                                    className="px-4 py-3 mt-2.5 w-full text-sm font-medium text-white placeholder:text-text-color whitespace-nowrap rounded-lg border border-solid bg-inputbox-color border-border-color focus:outline-none leading-[1.4rem]"
                                                    id="userName"
                                                    placeholder="Enter Name"
                                                    onChange={(e) => setNickName(e.target.value)}
                                                />
                                            </div>
                                            <div className="flex gap-2">
                                                <button
                                                    type="button"
                                                    className="p-2.5 w-full text-sm text-white whitespace-nowrap bg-red-600 rounded-lg border border-red-600 border-solid leading-[1.4rem]"
                                                    onClick={() => setOpenName(false)}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className={`p-2.5 w-full text-sm text-white whitespace-nowrap bg-border-color rounded-lg border border-border-color border-solid leading-[1.4rem] ${loading ? "opacity-50" : ""
                                                        }`}
                                                    disabled={loading}
                                                >
                                                    <span className="submit-label">
                                                        {loading ? "Processing..." : "Submit"}
                                                    </span>
                                                </button>
                                            </div>
                                        </form>
                                    </>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                )
            }
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        userDetails: state.userDetails?.userDetails,
        userThumbnail: state.userDetails.Thumbnail,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        setUserThumbnail: (data: any) => {
            dispatch(setUserThumbnail(data));
        },
        setUserName: (data: any) => {
            dispatch(setUserName(data));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
