import React, { useEffect, useState } from "react";
import exchangeIcon from "./../assets/images/exchange.svg";
import sbIcon from "./../assets/images/sportsbook.svg";
import API from "../services";
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import 'swiper/css/pagination';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "./SportsPage.scss"
import { S3_IMG } from "../constants/common";
import { RootState } from "../models/RootState";
import { connect } from "react-redux";
import { setSportsData } from "../store/authReducer";
import { FavoriteBorderOutlined, FavoriteOutlined } from "@mui/icons-material";
import Loader from "../assets/images/Strike_White_Logo_Transparent_GIF.gif";


interface SportsPageProps {
  sportsData: any;
  setSportsData: (data: any) => void;
}

const SportsPage: React.FC<SportsPageProps> = ({ sportsData, setSportsData }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [gameSrc, setGameSrc] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [gameData, setGameData] = useState<any>();

  const getSportsInfo = async () => {
    setLoading(true);
    let response;
    try {
      response = await API.post(`/webapp/get-sports-content`, {
      });
    } catch (e) {
      console.log(e);
      setLoading(false);
      return;
    }

    if (response.data.status !== "RS_OK") {
      navigate("/home");
    }

    if (response && response.data.sections) {
      setGameData(response.data?.sections);
      setSportsData(response.data?.sections);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (sportsData && sportsData.length > 0) {
      setGameData(sportsData);
    } else {
      getSportsInfo();
    }
    sessionStorage.setItem("previousPath", location.pathname);
  }, [])

  const handleFavoriteClick = async (game: any) => {
    setGameData((prevGameData: any) =>
      prevGameData?.map((section: any) => ({
        ...section,
        items: section?.items?.map((gameMap: any) =>
          gameMap?.id === game?.id
            ? { ...gameMap, isFavorite: !gameMap?.isFavorite }
            : gameMap
        ),
      }))
    );
    let response;
    try {
      // Make the API call to update the favorite status
      response = await API.post('/webapp/set-favorite', {
        category: game.category,
        id: game.id,
        isFavorite: !game.isFavorite,
        name: game?.name,
        provider: game?.provider,
        subProvider: game.subProvider,
        thumbnail: game.thumbnail,
        type: game.type,
        tgProviderId: game?.tgProviderId,
        tableId: game?.tableId,
        userKey: sessionStorage.getItem("userKey")
      });

    } catch (error) {
      console.error('Error updating favorite status:', error);
      // Revert the state change if the API call fails
    }

    if (response && response.data?.status === "RS_OK") {
      const updatedGameData = gameData?.map((section: any) => ({
        ...section,
        items: section?.items?.map((gameMap: any) =>
          gameMap?.id === game?.id
            ? { ...gameMap, isFavorite: !gameMap?.isFavorite }
            : gameMap
        ),
      }));
      setSportsData(updatedGameData);
    }
  };

  return (
    <div className="flex flex-col px-3 mt-4 w-full pb-[7rem] py-[4rem] casino">
      {
        loading ? (
          <div className="home-loading">
            <img src={Loader} style={{ height: '10rem' }} />
          </div>
        ) : (
          <>
            <div className="grid mb-2 justify-around grid-cols-2 gap-4">
              <NavLink to={`/play/${btoa(`::EX123:::site::`)}`}
                className={`nav-link`}>
                <div
                  className="flex-1 p-4 px-8 mx-auto bg-inputbox-color border border-border-color rounded-lg flex flex-col items-center"
                >
                  <div className="flex-col flex items-center justify-center">
                    <img src={exchangeIcon} alt="Exchange" className="w-6 h-8" />
                    <span className="text-sm font-medium text-white">EXCHANGE</span>
                  </div>
                </div>
              </NavLink>
              <NavLink to={`/play/${btoa(`::SB123:::site::`)}`}
                className={`nav-link`}>
                <div
                  className="flex-1 p-4 px-8 mx-auto bg-inputbox-color border border-border-color rounded-lg flex flex-col items-center"
                >
                  <div className="flex-col flex items-center justify-center">
                    <img src={sbIcon} alt="sportsbook" className="w-6 h-8" />
                    <span className="text-sm font-medium text-white">SPORTSBOOK</span>
                  </div>
                </div>
              </NavLink>
            </div>

            <div className="main-section">
              {gameData?.map((section: any, index: number) => (
                <div key={index} style={{ marginBottom: '10px' }}>
                  <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '0.75rem' }}>
                    <div>
                      <img src={section?.sectionIcon !== "" ? section?.sectionIcon : `${S3_IMG}/casino.svg`} alt={section?.sectionTitle} style={{ width: '1rem', marginRight: '5px' }} />
                    </div>
                    <span className="section-title">{section.sectionTitle}</span>
                  </div>
                  <Swiper
                    spaceBetween={10}
                    slidesPerView={2.1}
                    breakpoints={{
                      640: { slidesPerView: 3.5, spaceBetween: 10 },
                      768: { slidesPerView: 3.5, spaceBetween: 20 },
                      1024: { slidesPerView: 4, spaceBetween: 30 },
                    }}
                  >
                    {section?.items.map((game: any, gameIndex: number) => (
                      <SwiperSlide key={game?.id + gameIndex}>
                        <div style={{}}>
                          <NavLink
                            to={`/play/${btoa(`${game?.id}::${game?.tgProviderId}:${game?.provider}:${game?.subProvider}:${game?.type}:${game?.category}:${game?.tableId ? game?.tableId : ""}`)}`}
                            className={`nav-link`}
                          >
                            <div className="game-img relative" style={{ marginBottom: '0.5rem', height: '7.5rem', width: '10rem' }}>
                              <img src={game?.thumbnail} alt={game?.gameName} style={{ width: '100%', height: '100%', borderRadius: '12px' }} />
                              <div style={{ position: 'absolute', top: '5px', right: '5px' }}>
                                {game?.isFavorite ? <FavoriteOutlined
                                  className="gold-favourite-img"
                                  titleAccess={"remove from favourites"}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleFavoriteClick(game);
                                  }}
                                /> : <FavoriteBorderOutlined
                                  className="red-favourite-img"
                                  titleAccess={"remove from favourites"}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleFavoriteClick(game);
                                  }}
                                />}
                              </div>
                            </div>
                          </NavLink>
                          <div className="game-name">{game?.name}</div>
                          <div className="prov-name">{game?.provider}</div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              ))}

            </div>
          </>
        )
      }
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    sportsData: state.userDetails?.sportsData,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    setSportsData: (data: any) => {
      dispatch(setSportsData(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SportsPage);
