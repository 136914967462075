import axios from "axios";
import { getBaseUrl } from "./environment-url";

const API = axios.create({
  baseURL: getBaseUrl(process.env.REACT_APP_NODE_ENV, "REACT_APP_REST_API_URL"),
  responseType: "json",
  withCredentials: false,
  timeout: 10000, // 10 seconds
});

//Add a response interceptor
API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      console.log("***********Unauthorized Error**********");
    }
    return Promise.reject(error);
  }
);

// Add a request interceptor
API.interceptors.request.use((config) => {
  // Fetch the key from sessionStorage before each request
  const keyValue = sessionStorage.getItem("userKey") || "";
  
  // Set the key in headers dynamically
  config.headers['token'] = keyValue;
  
  return config;
}, (error) => {
  // Handle the error
  return Promise.reject(error);
});


export default API;
