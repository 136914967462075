import React, { useEffect, useState } from "react";
import PaymentDialog from "./PaymentDialog";
import { CloseOutlined } from "@mui/icons-material";
import { Dialog } from "@mui/material";
import { toast } from "react-toastify";
import { PaymentDetails } from "../../models/PaymentDetails";
import API from "../../services";
import { RootState } from "../../models/RootState";
import { connect } from "react-redux";
import { UserDetails } from "../../models/UserDetails";
import "./DepositForm.scss";
import DownArrow from '../../assets/images/down-arrow.svg';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import QRCode from "react-qr-code";

interface DepositFormProps {
  activeTab: string;
  action: string;
  userDetails: UserDetails;
}

const DepositForm: React.FC<DepositFormProps> = (props) => {
  const { activeTab, userDetails } = props;
  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [showPayment, setShowPayment] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentProps, setPaymentProps] = useState<PaymentDetails | null>(null);
  const isDisabled = loading || amount === undefined || amount <= 0 || userDetails?.MobileNumber === "";
  const userKey = new URLSearchParams(window.location.search).get("id");
  const [activeCurType, setActiveCurType] = useState<string>('Fiat');
  const [selectedCurrency, setSelectedCurrency] = useState<any>();
  const [isOpen, setIsOpen] = useState(false);
  const [currList, setCurrList] = useState<any>([]);
  const [walletAddress, setWalletAddress] = useState<any>("");

  const closeDialog = () => {
    setShowPayment(false);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (amount === undefined) return; // Ensure amount is provided

    setLoading(true);
    try {
      const response = await API.post(`/account/initiateDeposit`, {
        uKey: sessionStorage.getItem("userKey"),
        amount: amount,
        currency: "INR",
        tgProviderId: "PG123",
      });

      const data = response.data;
      if (data.status !== "RS_OK") {
        toast.error(`${data.errorDescription}`);
        return;
      }

      const paymentOptions: PaymentDetails = {
        referenceId: data.data.reference_id,
        bankId: "",
        qrCode: "",
        upiIntent: "",
        isUpi: false,
        actId: "",
        actName: "",
        ifsc: "",
        bankName: "",
        isBankTransfer: false,
        upiId: "",
      };

      data.data.payment_options.forEach((option: any) => {
        if (option.payment_method === "UPI") {
          paymentOptions.bankId = option.payment_method_details.bank_id;
          paymentOptions.qrCode = option.payment_method_details.upi_qr;
          paymentOptions.upiIntent =
            option.payment_method_details.upi_intent.replace("upi://", "");
          paymentOptions.isUpi = true;
          paymentOptions.upiId = option?.payment_method_details?.upi_id;
        } else if (option.payment_method === "BANK_TRANSFER") {
          paymentOptions.actId =
            option.payment_method_details.bank_account_number;
          paymentOptions.actName =
            option.payment_method_details.bank_account_holder;
          paymentOptions.ifsc = option.payment_method_details.ifs_code;
          paymentOptions.bankName = option.payment_method_details.bank_name;
          paymentOptions.bankId = option.payment_method_details.bank_id;
          paymentOptions.isBankTransfer = true;
        }
      });

      setPaymentProps(paymentOptions);
      setShowPayment(true);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setLoading(false);
    }
  };

  const getCurrList = async () => {
    let response;
    try {
      response = await API.post(`account/get-crypto-currencies`, {
        tgProviderId: "CR123"
      });
    } catch (e) {
      return;
    }
    if (response && response.data?.currenciesList) {
      setCurrList(response?.data?.currenciesList)
    }
  }

  const getWalletAddress = async (curr: any) => {
    let response;
    try {
      response = await API.post(`account/get-wallet-address`, {
        tgProviderId: "CR123",
        currency: curr?.crypto_currency,
        networkId: curr?.network_id,
      });
    } catch (e) {
      return;
    }
    if (response?.data?.status !== "RS_OK") {
      toast?.error(response?.data?.errorDescription)
    }

    if (response) {
      setWalletAddress(response?.data?.walletAddress)
    }
  }

  const handleTabClick = (tab: any) => {
    setActiveCurType(tab);
    if (tab === "Fiat") {
    } else {
      getCurrList();
      setLoading(false);
    }
  };

  const copyToClipboard = (text: any) => {
    navigator?.clipboard?.writeText(text)?.then(() => {
    });
  }

  const handleCurrencySelect = (curr: any) => {
    setSelectedCurrency(curr);
    getWalletAddress(curr)
    setIsOpen(false);
  };
  const toggleDropdown = () => setIsOpen(!isOpen);


  const tabs = [
    {
      label: "Fiat",
      content: (
        <>
          {/* <DepositForm activeTab="Deposit" action="fiat" ></DepositForm> */}
          {!showPayment && (
            <form className="flex flex-col w-full" onSubmit={handleSubmit}>
              <label htmlFor="amount" className="block mt-3 text-sm text-zinc-400">
                Amount
              </label>
              <input
                type="number"
                id="amount"
                className="px-4 py-3 mt-2.5 w-full text-sm font-medium text-white placeholder:text-text-color whitespace-nowrap rounded-lg border border-solid bg-inputbox-color border-border-color focus:outline-none leading-[1.4rem]"
                placeholder="Enter your amount"
                value={amount || ""}
                min={200}
                max={5000}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  setAmount(value);
                }}
              />
              {amount != null && (amount < 200 || amount > 5000) && (
                <span className="mt-1 text-sm text-orange-500">
                Amount must be between 200 and 5000.
                </span>
              )}
              <button
                type="submit"
                className={`p-2.5 mt-4 w-full text-sm text-white whitespace-nowrap bg-border-color rounded-lg border border-border-color border-solid leading-[1.4rem] ${isDisabled ? "opacity-50" : ""
                  }`}
                disabled={isDisabled || amount < 200 || amount > 5000}
              >
                {userDetails?.MobileNumber ? (loading ? "Processing..." : "Submit") : "Add Mobile Number"}
              </button>
            </form>
          )
          }

          {showPayment && paymentProps && (
            <PaymentDialog
              paymentProps={paymentProps}
              closeDialog={closeDialog}
            />
          )}
        </>
      )
    },
    {
      label: "Crypto",
      content: (
        <>
          <div className="crypto mt-2">
            <div className="flex justify-between">
              <div className="text-border-color text-lg">select currency</div>
              <div className="relative w-1/2 h-[30px]">
                <button
                  onClick={toggleDropdown}
                  className="flex items-center h-full justify-between px-3 py-2 w-full text-xs font-medium text-white whitespace-nowrap rounded-lg border border-solid bg-inputbox-color border-slate-800"
                >
                  <span className="truncate">
                    {selectedCurrency?.crypto_currency
                      ? `${selectedCurrency?.crypto_currency} (${selectedCurrency?.blockchain})`
                      : ""}
                  </span>
                  <img
                    loading="lazy"
                    src={DownArrow}
                    alt=""
                    className="shrink-0 ml-2 w-2.5 aspect-[1.67]"
                  />
                </button>

                {isOpen && (
                  <ul className="absolute z-10 w-full left-0 top-full mt-1 bg-inputbox-color border border-slate-800 rounded-lg shadow-lg">
                    {currList?.map((curr: any) => (
                      <li
                        key={curr}
                        onClick={() => handleCurrencySelect(curr)}
                        className="px-3 py-2 text-xs text-white hover:bg-zinc-700 cursor-pointer"
                      >
                        {`${curr?.crypto_currency} (${curr?.blockchain})`}
                      </li>
                    ))}
                  </ul>
                )}
              </div>

            </div>
            {
              walletAddress ? (
                <>
                  <label htmlFor="wallet" className="block mt-3 text-sm text-zinc-400">
                    Your {selectedCurrency?.crypto_currency} Deposit Address
                  </label>
                  <div
                    id="wallet"
                    className="flex items-center justify-between px-4 py-3 mt-2.5 w-full text-sm font-medium text-white placeholder:text-text-color whitespace-nowrap rounded-lg border border-solid bg-inputbox-color border-border-color focus:outline-none leading-[1.4rem]"
                  >
                    <span className="truncate text-xs">{walletAddress}</span>
                    <ContentCopyIcon
                      className="cursor-pointer hover:text-gray-800 ml-2"
                      onClick={() => copyToClipboard(walletAddress)}
                      titleAccess="Copy to clipboard"
                    />
                  </div>
                  <div className="mt-2.5 flex justify-center w-full">
                    <div className="p-1 bg-white">
                      <QRCode value={walletAddress} size={128} />
                    </div>
                  </div>
                  <div className="flex flex-col items-center text-white mt-1">
                    <p className="text-center text-sm">
                      Scan the QR code, or copy the wallet address to make a Crypto deposit.
                    </p>
                  </div>
                </>
              ) : <>
              </>
            }
          </div>
        </>
      )
    },

  ]


  return (
    <>
      <div className="deposit-tabs">
        <div className="wallet-nav">
          <div className="nav nav-pills" role="tablist">
            {tabs.map((tab) => (
              <div className="nav-item" key={tab.label}>
                <a
                  role="tab"
                  className={`nav-link ${activeCurType === tab.label ? "active" : ""}`}
                  onClick={() => handleTabClick(tab.label)}
                >
                  {tab.label}
                </a>
              </div>
            ))}
          </div>

          <div className="tab-content">
            {tabs.map((tab) => (
              <div
                key={tab.label}
                className={`tab-pane fade ${activeCurType === tab.label ? "show active" : ""
                  }`}
                id={`tabs-tabpane-${tab.label}`}
                role="tabpanel"
              >
                {activeCurType === tab.label && tab.content}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    userDetails: state.userDetails?.userDetails,
  };
};


export default connect(mapStateToProps)(DepositForm);
