
export const StageUrlsMap = {
  REACT_APP_REST_API_URL: `https://stage-core.strike46.com/api/v1`,
};

export const ProdUrls = {
  REACT_APP_REST_API_URL: `https://core.strike46.com/api/v1`,
};

export const EnvUrlsMap: { [key: string]: any } = {
  stage: StageUrlsMap,
  production: ProdUrls,
};

export function getBaseUrl(env: any, url: string) {
  return EnvUrlsMap[env][url];
}
