import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, NavLink, useParams } from "react-router-dom";
import API from "../../services";
import "./BannerInfo.scss"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    FaTelegramPlane,
    FaWhatsapp,
    FaEnvelope,
} from "react-icons/fa";
import { IoLogoFacebook, IoLogoTwitter } from "react-icons/io";

const BannerInfo: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const banner = location.state?.banner;
    const [loading, setLoading] = useState<boolean>(false);
    const [bannerData, setBannerData] = useState<any>(null);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const modalRef = useRef<HTMLDivElement>(null);
    const [refUrl, setRefUrl] = useState("");




    const getBannerInfo = async () => {
        setLoading(true);

        let response;
        try {
            response = await API.post(`/webapp/get-promotion-content`, {
                promotionId: banner?.promotionId
            });
        } catch (e) {
            console.log(e);
            setLoading(false);
            return;
        }

        if (response.data.status !== "RS_OK") {
            navigate("/home");
        }

        if (response && response.data?.promoContent) {
            setBannerData(response.data?.promoContent);
            if (bannerData?.htmlContent) {
                gameRules();
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        if (bannerData) {
            gameRules();
        }
    }, [bannerData]);

    const gameRules = async () => {
        try {
            let contentElement = document.getElementById("content");
            if (contentElement) {
                contentElement.innerHTML = `${bannerData?.htmlContent || "<div></div>"}`;
            } else {
                console.error("Element with ID 'content' not found.");
            }
        } catch (err) {
            console.log(err);
        }
    };

    const shareDetails = {
        title: "BotYaPot",
        text: `${bannerData?.promoLink} \n \n This is the place to get All the Fun`,
    };


    useEffect(() => {
        getBannerInfo();
    }, []);

    const handleShare = () => {
        setRefUrl(bannerData?.promoLink);
        if (navigator?.share) {
            navigator
                ?.share({
                    title: shareDetails.title,
                    text: `${bannerData?.promoLink} \n\n ${bannerData?.promoMessage}\n\n `,
                    url: bannerData?.promoLink,
                })
                .then(() => {
                    console.log("Successfully shared");
                })
                .catch((error) => {
                    console.error("Error sharing:", error);
                    // setIsShareModalOpen(true);
                });
        } else {
            setIsShareModalOpen(true);
        }
    };

    const handleClick = () => {
        if (bannerData?.promoLink) {
            window?.open(bannerData?.promoLink, "_blank");
        }
    };

    return (
        <>
            <div className="flex flex-col px-6 mt-4 pb-[7rem] py-[4rem] bannerinfo">
                <div className="flex items-center">
                    <div className="bg-inputbox-color flex p-1 mr-[0.5rem] rounded"
                        onClick={() => navigate("/home")}
                    >
                        <ArrowBackIcon
                            className="text-border-color aspect-square w-[18px]"
                            style={{ width: '20px', height: '20px' }}
                        />
                    </div>
                    <div>Go Back</div>
                </div>
                <div className="py-3">
                    <img
                        src={banner?.promotionImage}
                        alt="banner"
                        style={{ height: '100%', width: '100%', borderRadius: '1rem' }}
                    />
                </div>

                <div className="w-full flex justify-center">
                    <button
                        className={`p-2.5 mt-2 w-3/5 text-sm text-white whitespace-nowrap bg-border-color rounded-lg border border-border-color border-solid leading-[1.4rem]`}
                        onClick={() => bannerData?.isShare ? handleShare() : handleClick()}
                    >
                        REFER NOW!!!!
                    </button>
                </div>
                <div className="mt-2 rules-content-ctn">
                    <div className="content" id="content"></div>
                </div>
                <div className="w-full flex justify-center">
                    <button
                        className={`p-2.5 mt-2 w-3/5 text-sm text-white whitespace-nowrap bg-border-color rounded-lg border border-border-color border-solid leading-[1.4rem]`}
                        onClick={() =>bannerData?.isShare ? handleShare() : handleClick()}
                    >
                        HURRY REFER NOW!!!!
                    </button>
                </div>
            </div>

            {isShareModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
                    <div
                        ref={modalRef}
                        className="bg-inputbox-color p-6 rounded-lg w-full max-w-md"
                    >
                        <h3 className="text-white text-lg mb-4 text-center">
                            Share Options
                        </h3>
                        <div className="flex justify-around">
                            <button
                                className="p-2 hover:text-blue-500"
                                onClick={() =>
                                    window.open(
                                        `https://twitter.com/intent/tweet?text=${encodeURIComponent(
                                            shareDetails.text
                                        )}&url=${encodeURIComponent(refUrl)}`,
                                        "_blank"
                                    )
                                }
                            >
                                <IoLogoTwitter size={24} color="#1DA1F2" /> {/* Twitter Blue */}
                            </button>
                            <button
                                className="p-2 hover:text-blue-700"
                                onClick={() =>
                                    window.open(
                                        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                                            refUrl
                                        )}`,
                                        "_blank"
                                    )
                                }
                            >
                                <IoLogoFacebook size={24} color="#4267B2" />{" "}
                                {/* Facebook Blue */}
                            </button>
                            <button
                                className="p-2 hover:text-green-500"
                                onClick={() =>
                                    window.open(
                                        `whatsapp://send?text=${encodeURIComponent(
                                            shareDetails.text
                                        )}%20${encodeURIComponent(refUrl)}`,
                                        "_blank"
                                    )
                                }
                            >
                                <FaWhatsapp size={24} color="#25D366" /> {/* WhatsApp Green */}
                            </button>
                            <button
                                className="p-2 hover:text-blue-400"
                                onClick={() =>
                                    window.open(
                                        `tg://msg_url?url=${encodeURIComponent(
                                            refUrl
                                        )}&text=${encodeURIComponent(shareDetails.text)}`,
                                        "_blank"
                                    )
                                }
                            >
                                <FaTelegramPlane size={24} color="#0088cc" />{" "}
                                {/* Telegram Blue */}
                            </button>
                            <button
                                className="p-2 hover:text-gray-300"
                                onClick={() =>
                                    window.open(
                                        `mailto:?subject=${encodeURIComponent(
                                            shareDetails.title
                                        )}&body=${encodeURIComponent(refUrl)}`,
                                        "_blank"
                                    )
                                }
                            >
                                <FaEnvelope size={24} color="#D44638" /> {/* Mail Red */}
                            </button>
                        </div>
                        <button
                            className="mt-4 w-full bg-meicnbg-color text-white px-4 py-2 rounded hover:bg-gray-700"
                            onClick={() => setIsShareModalOpen(false)}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default BannerInfo;

