import React, { useEffect, useState, useRef, useCallback } from "react";
import TransactionHistoryItem from "./TransactionHistoryItem";
import { useNavigate } from "react-router-dom";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import API from "../../../services";
import { TxnList } from "../../../models/TransactionList";

const formatDate = (epochTime: number): string => {
  const now = new Date();
  const date = new Date(epochTime);
  const diffTime = now.getTime() - date.getTime();
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays === 0) return "Today";
  if (diffDays === 1) return "Yesterday";
  if (diffDays < 7)
    return date.toLocaleDateString("en-US", { weekday: "long" });
  return date.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
};

const dateFormat = (time: any) => {
  const matchStartTime = new Date(time);

  const formattedStartTime = matchStartTime.toLocaleString("en-GB", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
  });

  return formattedStartTime
}

const TransactionHistory: React.FC = () => {
  const [txnList, setTxnList] = useState<TxnList[]>([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const observer = useRef<IntersectionObserver | null>(null);
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  const loadMoreTransactions = useCallback(async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      const userKey = sessionStorage.getItem("userKey");
      const res = await API.post("/account/get-transactions", {
        userKey,
        page,
        pageSize: 50,
      });

      if (res.data.status === "RS_OK") {
        const data = res?.data?.transactions || [];
        setTxnList((prev) => [...prev, ...data]);
        setHasMore(data.length === 50);
        setPage((prev) => prev + 1);
      } else {
        console.error("Error fetching transactions");
        setHasMore(false);
      }
    } catch (error) {
      console.error("Failed to load transactions", error);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  }, [page, hasMore, loading]);

  useEffect(() => {
    const handleIntersection = ([entry]: IntersectionObserverEntry[]) => {
      if (entry.isIntersecting && !loading && hasMore) {
        loadMoreTransactions();
      }
    };

    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    });

    const sentinel = document.getElementById("sentinel");
    if (sentinel) {
      observer.current.observe(sentinel);
    }

    return () => {
      if (observer.current && sentinel) {
        observer.current.unobserve(sentinel);
      }
    };
  }, [loadMoreTransactions, loading, hasMore]);

  const handleRowClick = (row: any) => {
    navigate("/transaction-history-details", { state: { details: row } });
  }

  return (
    <section className="flex flex-col px-4 mt-4 pb-[7rem] py-[4rem] w-full">
      <header className="flex gap-3 items-center self-start text-base font-medium text-center text-white mb-[0.5rem]">
        <FontAwesomeIcon
          icon={faChevronLeft}
          onClick={handleBackClick}
          className="cursor-pointer object-contain shrink-0 self-stretch my-auto w-[12px] aspect-[0.57]"
        />
        <h2 className="self-stretch my-auto">Transaction History</h2>
      </header>
      {txnList.map((transaction, index) => (
        <TransactionHistoryItem
          key={index}
          referenceId={transaction?.referenceId}
          type={transaction.transactionType}
          title={transaction.transactionType}
          amount={transaction?.amount}
          date={dateFormat(transaction?.time)}
          status={transaction.status.toLowerCase()}
          onClick={() => handleRowClick(transaction)}
        />
      ))}
      {loading && <p className="text-white justify-center flex">Loading...</p>}
      <div id="sentinel" />
    </section>
  );
};

export default TransactionHistory;
