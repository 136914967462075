import React, { useEffect, useState } from "react";
import { IonSpinner } from "@ionic/react";
import API from "../../services";
import { toast } from "react-toastify";
import { fetchUserIp } from "../../constants/common";
import { useNavigate, useParams } from "react-router-dom";
import { CancelOutlined } from "@mui/icons-material";
import { useDrag } from '@use-gesture/react';
import { useSpring, animated } from '@react-spring/web';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import "./PlayGame.scss"
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LogoutIcon from '@mui/icons-material/Logout';


const PlayGame: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [gameSrc, setGameSrc] = useState<string | null>(null);
  const { gameInfo } = useParams();
  const [gameId, sportId, tgProv, provider, subProv, type, category, tableId] = atob(gameInfo || "").split(":");

  const getGameUrl = async (userIp: string) => {
    setLoading(true);
    let response;
    try {
      response = await API.post(`/pgapi/get-login-url`, {
        userKey: sessionStorage.getItem("userKey"),
        tgProviderId: tgProv,
        clientIp: userIp,
        gameId: gameId,
        sportId: sportId,
      });
    } catch (e) {
      setLoading(false);
      return;
    }

    if (response.data.status !== "RS_OK") {
      toast.error(response.data.errorDescription);
      handleBackClick();
      return;
    }

    if (response) {
      if (response.data?.sessionUrl === "") {
        toast.error("Plz try again later");
        handleBackClick();
      }
      setGameSrc(response.data.sessionUrl);
    }
    setLoading(false);
  };

  const getSportsUrl = async (userIp: string) => {
    setLoading(true);
    let response;
    try {
      response = await API.post(`/webapp/launch-sports`, {
        clientIp: userIp,
        id: gameId,
        provider: provider,
        subProvider: subProv,
        tgProviderId: tgProv,
        type: type
      });
    } catch (e) {
      setLoading(false);
      return;
    }

    if (response.data.status !== "RS_OK") {
      toast.error(response.data.errorDescription);
      handleBackClick();
      return;
    }
    if (response) {
      if (response.data?.sessionUrl == "") {
        toast.error("Plz try again later");
        handleBackClick();
      }
      setGameSrc(response.data.sessionUrl);
    }
    setLoading(false);
  }

  const getSlotsUrl = async (userIp: string) => {
    setLoading(true);
    let response;
    try {
      response = await API.post(`/webapp/launch-slots`, {
        clientIp: userIp,
        id: gameId,
        provider: provider,
        subProvider: subProv,
        tgProviderId: tgProv,
        type: type,
        tableId: tableId
      });
    } catch (e) {
      setLoading(false);
      handleBackClick();
      return;
    }

    if (response.data.status !== "RS_OK") {
      toast.error(response.data.errorDescription);
      handleBackClick();
      return;
    }
    if (response) {
      if (response.data?.sessionUrl == "") {
        toast.error("Plz try again later");
        handleBackClick();
      }
      setGameSrc(response?.data?.sessionUrl);
    }
    setLoading(false);
  }

  const getCasinoUrl = async (userIp: string) => {
    setLoading(true);
    let response;
    try {
      response = await API.post(`/webapp/launch-casino`, {
        clientIp: userIp,
        id: gameId,
        provider: provider,
        subProvider: subProv,
        tgProviderId: tgProv,
        type: type
      });
    } catch (e) {
      setLoading(false);
      return;
    }

    if (response.data.status !== "RS_OK") {
      toast.error(response.data.errorDescription);
      handleBackClick();
      return;
    }
    if (response) {
      if (response.data?.sessionUrl == "") {
        toast.error("Plz try again later");
        handleBackClick();
      }
      setGameSrc(response.data.sessionUrl);
    }
    setLoading(false);
  }

  const initialize = async () => {
    const userIp = await fetchUserIp();
    if (userIp) {
      if (type.toLocaleLowerCase() === "site") {
        getGameUrl(userIp);
      } else if (type.toLocaleLowerCase() === "game") {
        if (category.toLocaleLowerCase() === "slot" || category.toLocaleLowerCase() === "slots") {
          getSlotsUrl(userIp);
        } else {
          getCasinoUrl(userIp);
        }
      } else {
        getSportsUrl(userIp);
      }
    }
  };

  const navigate = useNavigate();

  const handleBackClick = () => {
    const path = sessionStorage.getItem("previousPath");
    if (path) {
      navigate(path);
    } else {
      navigate("/home");
    }
  };

  const fullScreen = () => {
    const tg = window?.Telegram?.WebApp;
    console.log("sdf", tg);
    tg?.requestFullscreen();
    // if( window?.Telegram?.WebApp?.i)
  }
  // const tg = window?.Telegram?.WebApp;

  // // Enable the BackButton
  // tg?.BackButton?.show();

  // // Optional: Set up an event listener for the BackButton click
  // tg?.BackButton?.onClick(() => {
  //   console?.log("Back button clicked!");
  //   // window.history.back(); // Navigate back in browser history
  //   handleBackClick();
  // });

  useEffect(() => {
    const tg = window?.Telegram?.WebApp;

    tg?.BackButton?.show();

    tg?.expand();

    const telegramVersion = parseFloat(tg?.version || "0");
    if (telegramVersion >= 8.0) {
      tg?.requestFullscreen();
    }

    // Adjust layout based on contentSafeAreaInset
    const adjustLayout = () => {
      const insets = tg?.ContentSafeAreaInset || { top: 0, bottom: 0, left: 0, right: 0 };
      document.body.style.paddingTop = `${insets.top}px`;
      document.body.style.paddingBottom = `${insets.bottom}px`;
      document.body.style.paddingLeft = `${insets.left}px`;
      document.body.style.paddingRight = `${insets.right}px`;
    };

    // adjustLayout();

    // tg?.onEvent('viewportChanged', adjustLayout);

    console.log("sdfgh", tg)

    tg?.BackButton?.onClick(() => {
      console.log("Back button clicked!");
      handleBackClick();
    });

    return () => {
      tg?.BackButton?.hide();
      const telegramVersion = parseFloat(tg?.version || "0");
      if (telegramVersion >= 8.0) {
        tg?.exitFullscreen();
      }
      // tg?.offEvent('viewportChanged', adjustLayout);
    };
  }, []);



  const [{ x, y }, api] = useSpring(() => ({ x: 16, y: 12 }));

  // Set up drag gesture
  const bind = useDrag(({ offset: [ox, oy] }) => {
    api.start({ x: ox, y: oy }); // Update the spring positions on drag
  }, {
    bounds: { left: 0, right: window.innerWidth, top: 0, bottom: window.innerHeight }, // Define draggable bounds within the window
    rubberband: true, // Add a bit of elastic effect at the edges
  });

  useEffect(() => {
    initialize();
  }, []);

  return (
    <div className="w-full h-full top-0 bottom-0 right-0 left-0 fixed">
      {gameSrc && (
        <>
          <iframe
            src={gameSrc}
            title="Game"
            allowFullScreen
            className="w-full h-full z-[1]"
            sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups"
          />
          {/* Draggable Container */}
          {/* <animated.div
            {...bind()}
            style={{
              x,
              y,
              position: 'absolute',
              top: 0,
              left: "40%",
              zIndex: 2,
              backgroundColor: '#2E4C4E',
              borderRadius: 4,
              // padding: '0.5rem',
              cursor: 'pointer',
              touchAction: 'none',
              transform: "rotate(180deg)"
            }}
            onClick={() => fullScreen()}
          >
            <ExitToAppIcon
              className="text-border-color"
              style={{
                fontSize: '2rem',
              }}
            />
          </animated.div> */}
        </>
      )}
      {loading && (
        <div className="flex justify-center items-center min-h-[92vh]">
          <div className="flex items-center fixed top-8 left-8">
            <div className="bg-inputbox-color flex p-1 mr-[0.5rem] rounded"
              onClick={() => handleBackClick()}
            >

              <ArrowBackIcon
                className="text-border-color aspect-square w-[18px]"
                style={{ width: '20px', height: '20px' }}
              />
            </div>
            <div className="text-border-color">Go Back</div>
          </div>
          <IonSpinner className="text-border-color" name="bubbles" />
        </div>
      )}
    </div>
  );
};

export default PlayGame;
