import React, { useEffect, useState } from "react";
import { IonSpinner } from "@ionic/react";
import API from "../../services";
import { toast } from "react-toastify";
import { NavLink, useLocation, useNavigation } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Dialog from "@mui/material/Dialog";
import { setActiveWallet, setWallets } from "../../store/authReducer";
import { connect } from "react-redux";
import { RootState } from "../../models/RootState";
import CloseIcon from '@mui/icons-material/Close';
import Loader from "../../assets/images/Strike_White_Logo_Transparent_GIF.gif";
import Strike from "../../assets/images/Strike46Logo.webp";
import { AiOutlineInfoCircle } from "react-icons/ai";

type StoreProps = {
  setActiveWallet: (data: any) => void;
  setWallets: (data: any) => void;
}

const Site: React.FC<StoreProps> = ({ setActiveWallet, setWallets }) => {
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [showDeposit, setshowDeposit] = useState<any>(false);
  const [siteData, setSiteData] = useState<any>();
  const [siteWallet, setSiteWallet] = useState<any>();
  const [showWithdraw, setShowWithdraw] = useState<any>(false);
  const [siteId, setSiteId] = useState<any>("");
  const [siteProvId, setSiteProvId] = useState<any>("");
  const [amount, setAmount] = useState<number | undefined>(undefined);
  const isDisabled = loading || amount === undefined || amount <= 0;
  const [showInfo, setShowInfo] = useState(false);

  const handleInfoClick = () => {
    setShowInfo(true);
    setTimeout(() => {
      setShowInfo(false);
    }, 5000); // Hide the info after 3 seconds
  };


  const getUserBalance = async () => {

    let response: any;
    try {
      response = await API.post(`/webapp/get-user-balance`, {
        userKey: sessionStorage.getItem("userKey"),
      });
    } catch (e) {
      return;
    }

    if (response.data.status !== "RS_OK") {
      toast.error(response.data.errDescription);
    }

    if (response && response.data.wallets) {
      setWallets(response.data?.wallets);
      setActiveWallet(response?.data?.activeWallet)
      const matchingWallet = response.data?.wallets.find(
        (wallet: any) => wallet?.walletName === response.data?.activeWallet
      );
    }
  };

  const getSiteDetails = async () => {
    setLoading(true);

    let response;
    try {
      response = await API.post(`/webapp/get-site-content`, {
      });
    } catch (e) {
      console.log(e);
      setLoading(false);
      return;
    }

    if (response.data.status !== "RS_OK") {
      toast.error(response.data.errDescription);
    }

    if (response && response.data?.sections) {
      setSiteData(response.data?.sections)
    }

    setLoading(false);
  };

  const getSiteWallet = async (site: any, type: any) => {
    let response;
    try {
      response = await API.post(`/webapp/get-site-wallet`, {
        tgProviderId: site?.tgProviderId
      });
    } catch (e) {
      console.log(e);
      return;
    }

    setSiteId(site?.id)
    setSiteProvId(site?.tgProviderId)

    if (response.data.status !== "RS_OK") {
      toast.error(response.data.errDescription);
    }

    if (response && response.data) {
      setSiteWallet(response.data)
      if (type === "deposit") {
        setshowDeposit(true);
      } else {
        setShowWithdraw(true)
      }
    }
  };

  useEffect(() => {
    getSiteDetails();
    sessionStorage.setItem("previousPath", location.pathname);
  }, []);

  // useEffect(() => {
  //   if(amount >= 1){

  //   }
  // },[amount])

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (amount === undefined) return; // Ensure amount is provided

    if (amount > (siteWallet?.walletBalance - siteWallet?.walletExposure)) {
      toast.error("Amount Cannot be greater than UserWallet");
      return
    }

    setLoading(true);
    try {
      const response = await API.post(`/webapp/site-deposit`, {
        amount: amount,
        siteId: siteId,
        tgProviderId: siteProvId,
      });

      const data = response.data;
      if (data.status !== "RS_OK") {
        toast.error(`${data.errDescription}`);
        return;
      } else {
        toast.success("Deposit Successful");
        setshowDeposit(false);
        setAmount(0);
        getSiteDetails();
        getUserBalance();
      }



    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleWithDrawSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (amount === undefined) return; // Ensure amount is provided

    if (amount > (siteWallet?.siteBalance - siteWallet?.siteExposure)) {
      toast.error("Amount Cannot be greater than Site Balance");
      return
    }

    setLoading(true);
    try {
      const response = await API.post(`/webapp/site-withdraw`, {
        amount: amount,
        siteId: siteId,
        tgProviderId: siteProvId,
      });

      const data = response.data;
      if (data.status !== "RS_OK") {
        toast.error(`${data.errorDescription}`);
        return;
      } else {
        toast.success("Withdrawal Successful");
        setShowWithdraw(false);
        setAmount(0);
        getSiteDetails();
        getUserBalance();
      }

    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-col px-3 mt-4 w-full pb-[7rem] py-[4rem] site">
        {

          loading ? (
            <div className="home-loading">
              <img src={Loader} style={{ height: '10rem' }} />
            </div>
          ) : (
            <>
              {
                siteData?.map((section: any, index: number) => (
                  <div className="relative w-full px-4 py-2 text-left text-white font-medium rounded-lg focus:outline-none border border-border-color">
                    <div className="absolute top-2 left-2">
                      <AiOutlineInfoCircle
                        className="text-white text-lg cursor-pointer"
                        onClick={handleInfoClick}
                      />
                      {showInfo && (
                        <div className="absolute top-8 left-0 z-10 w-60 p-2 text-sm text-white bg-gray-700 border border-gray-500 rounded-lg shadow-md">
                          You can send the amount {"(Points)"} to site using the send Button below <br/>
                          the amount would be send from current acount to site.<br/>
                          Similarly can recieve the amount from the Site to current TG account.
                        </div>
                      )}
                    </div>

                    <NavLink
                      to={`/play/${btoa(`${section?.id ? section?.id : ""}::FS123:::site::`)}`}
                      className={`nav-link`}
                    >
                      <div className="flex-1 p-4 px-8 mx-auto mb-2 w-2/3 bg-inputbox-color border border-border-color rounded-lg flex flex-col items-center">
                        <div className="items-center flex">
                          <img src={Strike} alt="site" />
                        </div>
                      </div>
                    </NavLink>
                    <div className="">
                      <div className="flex justify-center w-full gap-1">
                        <div className="flex justify-end w-3/5">Site Balance:</div>
                        <div className="w-2/5">{" "}{section?.balance}</div>
                      </div>
                      <div className="flex justify-center gap-1">
                        <div className="flex justify-end w-3/5">Exposure: </div>
                        <div className="w-2/5">{section?.exposure} </div>
                      </div>
                    </div>
                    <div className="flex relative gap-3 justify-center mt-2.5 text-sm font-medium text-white whitespace-nowrap">
                      <button className="px-9 py-2.5 rounded-lg flex-1 bg-inputbox-color border border-border-color"
                        onClick={() => getSiteWallet(section, "deposit")}>
                        Send
                      </button>
                      <button className="px-9 py-2.5 rounded-lg flex-1 bg-inputbox-color border border-border-color"
                        onClick={() => getSiteWallet(section, "withdraw")}>
                        Receive
                      </button>
                    </div>
                  </div>
                ))
              }
            </>
          )
        }
      </div>
      {
        showDeposit && (
          <Dialog
            fullScreen={false}
            open={showDeposit}
            onClose={() => {
              setshowDeposit(false);
              setAmount(0);
            }}
            PaperProps={{
              style: {
                position: 'absolute',
                top: '5rem'
              }
            }}
          >
            <div className="dialog-bg bg-inputbox-color relative p-4">
              {/* Close Button */}
              <CloseIcon
                className="absolute top-3 right-3 text-white text-2xl font-semibold"
                onClick={() => { setshowDeposit(false); setAmount(0) }}
              />
              <div>
                <div>
                  Available Balance: {siteWallet?.walletBalance - siteWallet?.walletExposure}
                </div>
                <div>
                  <>
                    <form className="flex flex-col w-full" onSubmit={handleSubmit}>
                      <label
                        htmlFor="amount"
                        className="block mt-3 text-sm text-zinc-400"
                      >
                        Amount
                      </label>
                      <input
                        type="number"
                        id="amount"
                        className="px-4 py-3 mt-2.5 w-full text-sm font-medium text-white placeholder:text-text-color whitespace-nowrap rounded-lg border border-solid bg-inputbox-color border-border-color focus:outline-none leading-[1.4rem]"
                        placeholder="Enter your amount"
                        value={amount || ""}
                        onChange={(e) => setAmount(Number(e.target.value))}
                      />

                      <div className="flex justify-between mt-4">
                        <button
                          type="submit"
                          className="p-2.5 w-[48%] text-sm text-white bg-border-color rounded-lg border border-border-color border-solid leading-[1.4rem]"
                        >
                          {"Send"}
                        </button>
                        <button
                          type="button"
                          className="p-2.5 w-[48%] text-sm text-white bg-error-color rounded-lg border border-error-color border-solid leading-[1.4rem]"
                          onClick={() => { setshowDeposit(false); setAmount(0) }}
                        >
                          {"Cancel"}
                        </button>
                      </div>
                    </form>
                  </>
                </div>
              </div>
            </div>
          </Dialog>
        )
      }
      {
        showWithdraw && (
          <Dialog
            fullScreen={false}
            open={showWithdraw}
            onClose={() => {
              setShowWithdraw(false)
              setAmount(0);
            }}
            PaperProps={{
              style: {
                position: 'absolute',
                top: '5rem'
              }
            }}
          >
            <div className="dialog-bg bg-inputbox-color relative p-4">
              <CloseIcon
                className="absolute top-3 right-3 text-white text-2xl font-semibold"
                onClick={() => { setShowWithdraw(false); setAmount(0) }}
              />
              <div>
                <div>
                  Available Balance: {siteWallet?.siteBalance - siteWallet?.siteExposure}
                </div>
                <div>
                  <>
                    <form className="flex flex-col w-full" onSubmit={handleWithDrawSubmit}>
                      <label
                        htmlFor="amount"
                        className="block mt-3 text-sm text-zinc-400"
                      >
                        Amount
                      </label>
                      <input
                        type="number"
                        id="amount"
                        className="px-4 py-3 mt-2.5 w-full text-sm font-medium text-white placeholder:text-text-color whitespace-nowrap rounded-lg border border-solid bg-inputbox-color border-border-color focus:outline-none leading-[1.4rem]"
                        placeholder="Enter your amount"
                        value={amount || ""}
                        onChange={(e) => setAmount(Number(e.target.value))}
                      />

                      <div className="flex justify-between mt-4">
                        <button
                          type="submit"
                          className="p-2.5 w-[48%] text-sm text-white bg-border-color rounded-lg border border-border-color border-solid leading-[1.4rem]"
                        >
                          {"Recieve"}
                        </button>
                        <button
                          type="button"
                          className="p-2.5 w-[48%] text-sm text-white bg-error-color rounded-lg border border-error-color border-solid leading-[1.4rem]"
                          onClick={() => { setShowWithdraw(false); setAmount(0) }}
                        >
                          {"Cancel"}
                        </button>
                      </div>
                    </form>
                  </>
                </div>
              </div>
            </div>
          </Dialog>
        )
      }
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    setWallets: (data: any) => {
      dispatch(setWallets(data));
    },
    setActiveWallet: (data: any) => {
      dispatch(setActiveWallet(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Site);
