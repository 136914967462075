import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "../../services";
import { connect } from "react-redux";
import { RootState } from "../../models/RootState";
import { UserDetails } from "../../models/UserDetails";
import DownArrow from '../../assets/images/down-arrow.svg';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import "./DepositForm.scss";
import Dialog from "@mui/material/Dialog";
import CloseIcon from '@mui/icons-material/Close';

interface DepositFormProps {
  activeTab: string;
  action: string;
  userDetails: UserDetails;
}

const WithdrawForm: React.FC<DepositFormProps> = (props) => {
  const { activeTab, userDetails } = props;
  const inputBoxClass =
    "px-4 py-3 mt-1.5 w-full text-sm font-medium text-white placeholder:text-text-color rounded-lg border border-solid bg-inputbox-color border-border-color focus:outline-none leading-[1.4rem]";
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accountHolderName, setAccountHolderName] = useState("");
  const [accountId, setAccountId] = useState("");
  const [bankName, setBankName] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [activeCurType, setActiveCurType] = useState<string>('Fiat');
  const [selectedCurrency, setSelectedCurrency] = useState<any>();
  const [isOpen, setIsOpen] = useState(false);
  const [currList, setCurrList] = useState<any>([]);
  const [walletAddress, setWalletAddress] = useState<any>("");
  const [cryptoAmount, setCryptoAmount] = useState<number | undefined>(undefined);
  const [openForm, setOpenForm] = useState<any>(false);
  const [nickName, setNickName] = useState<any>("");
  const [bankAccountSelected, setBankAccountSelected] = useState<any>();
  const [accList, setAccList] = useState<any>([]);
  const [openAmForm, setOpenAmForm] = useState<any>(false);

  const allFieldsFilled = () => {
    return (
      accountHolderName &&
      accountId &&
      bankName &&
      ifscCode &&
      nickName &&
      nickName?.length > 2 &&
      userDetails?.MobileNumber
    );
  };

  const allCryptoFieldsFilled = () => {
    return (
      selectedCurrency &&
      walletAddress &&
      cryptoAmount &&
      cryptoAmount > 0 &&
      userDetails?.MobileNumber
    );
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);
    setOpenAmForm(false)
    try {
      const response = await API.post(`/account/processWithdrawal`, {
        aoountName: bankAccountSelected?.name,
        amount: amount,
        uKey: sessionStorage.getItem("userKey"),
        tgProviderId: "PG123",
      });

      const data = await response.data;
      if (data.status !== "RS_OK") {
        toast.error(`${data.errorDescription}`);
      } else {
        toast.success("Transaction saved Succesfully");
      }

      setAmount(0);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAccountSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);
    setOpenForm(false)
    try {
      const response = await API.post(`/users/add-bank-account`, {
        actHolderName: accountHolderName,
        actNum: accountId,
        bName: bankName,
        ifsc: ifscCode,
        name: nickName,
      });

      const data = await response.data;
      if (data.status !== "RS_OK") {
        toast.error(`${data.errorDescription}`);
      } else {
        getAccList();
        toast.success("Account Added Succesfully");
      }
      setAccountHolderName("");
      setAccountId("");
      setNickName("");
      setIfscCode("");
      setBankName("");
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setLoading(false);
    }
  };

  const cryptoWithdrawal = async () => {
    setLoading(true);
    try {
      const response = await API.post(`/account/crypto-Withdrawal`, {
        amount: cryptoAmount,
        cryptoCurrency: selectedCurrency?.crypto_currency,
        network_id: selectedCurrency?.network_id,
        walletAddress: walletAddress,
        tgProviderId: "CR123",
      });

      const data = await response.data;
      if (data.status !== "RS_OK") {
        toast.error(`${data.errorDescription}`);
      } else {
        toast.success("Transaction saved Succesfully");
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setLoading(false);
    }
  }



  const getCurrList = async () => {
    let response;
    try {
      response = await API.post(`account/get-crypto-currencies`, {
        tgProviderId: "CR123"
      });
    } catch (e) {
      return;
    }
    if (response?.data?.status !== "RS_OK") {
      toast?.error(response?.data?.errorDescription)
    }
    if (response && response.data?.currenciesList) {
      setCurrList(response?.data?.currenciesList)
    }
  }

  const getAccList = async () => {
    let response;
    try {
      response = await API.post(`/users/get-bank-accounts`, {
      });
    } catch (e) {
      return;
    }
    if (response?.data?.status !== "RS_OK") {
      toast?.error(response?.data?.errorDescription)
    }
    if (response && response.data?.bankAccounts) {
      setAccList(response?.data?.bankAccounts)
    }
  }

  const handleCurrencySelect = (curr: any) => {
    setSelectedCurrency(curr);
    setIsOpen(false);
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleTabClick = (tab: any) => {
    setActiveCurType(tab);
  };

  useEffect(() => {
    if (activeCurType === "Fiat") {
      getAccList();
    } else {
      getCurrList();
      setLoading(false);
    }
  }, [activeCurType])


  const tabs = [
    {
      label: "Fiat",
      content: (
        <>
          {!userDetails?.MobileNumber && (
            <div className="text-lg mb-1 mb-4">
              Please share Contact before Trying to Withdraw the Amount.
            </div>
          )}

          {
            userDetails?.MobileNumber && (
              <>
                <header className="flex gap-3 items-center mt-1 self-start text-base font-medium text-center text-white mb-[0.5rem] w-full justify-end">
                  <button
                    onClick={() => setOpenForm(true)}
                    className="flex items-center justify-between px-3 py-2 text-xs font-medium text-white whitespace-nowrap rounded-lg border border-solid bg-inputbox-color border-slate-800"
                  >
                    <span>+ Add Bank Account</span>
                  </button>
                </header>

                {
                  accList && (
                    accList?.map((row: any) => (
                      <>
                        <div className="flex gap-5 justify-between p-4 mb-2 w-full rounded-lg bg-meitms-color"
                          onClick={() => { setBankAccountSelected(row); setOpenAmForm(true) }}
                        >
                          <div className="flex gap-3 w-full justify-between items-center font-medium h-[41px]">
                            <div className="text-sm text-white">{row?.name}</div>
                            <div className="text-sm text-white">{row?.accountNumber}</div>
                          </div>
                        </div>
                      </>
                    ))
                  )
                }
              </>
            )
          }

          {
            openForm && (
              <Dialog
                fullScreen={false}
                open={openForm}
                onClose={() => setOpenForm(false)}
                PaperProps={{
                  style: {
                    position: 'absolute',
                    top: '2rem'
                  }
                }}
              >
                <div className="withdrawdialog-bg bg-inputbox-color relative p-4">
                  <CloseIcon
                    className="absolute top-3 right-3 text-white text-2xl font-semibold"
                    onClick={() => setOpenForm(false)}
                  />
                  <div>
                    <div>
                      Add Bank Account
                    </div>
                    <div>
                      <>
                        <form className="flex flex-col w-full" onSubmit={handleAccountSubmit}>
                          <label
                            htmlFor="accountHolderName"
                            className="block mt-3 text-sm text-zinc-400"
                          >
                            Account Holder Name
                          </label>
                          <input
                            type="text"
                            id="accountHolderName"
                            className={`${inputBoxClass} ${!userDetails?.MobileNumber ? "bg-gray-100 cursor-not-allowed" : ""
                              }`}
                            placeholder="Account Holder Name"
                            value={accountHolderName}
                            onChange={(e) => setAccountHolderName(e.target.value)}
                            disabled={!userDetails?.MobileNumber}
                          />

                          <label htmlFor="accountId" className="block mt-3 text-sm text-zinc-400">
                            Account ID
                          </label>
                          <input
                            type="number"
                            id="accountId"
                            className={`${inputBoxClass} ${!userDetails?.MobileNumber ? "bg-gray-100 cursor-not-allowed" : ""
                              }`}
                            placeholder="Account ID"
                            value={accountId}
                            onChange={(e) => setAccountId(e.target.value)}
                            disabled={!userDetails?.MobileNumber}
                          />

                          <label htmlFor="bankName" className="block mt-3 text-sm text-zinc-400">
                            Bank Name
                          </label>
                          <input
                            type="text"
                            id="bankName"
                            className={`${inputBoxClass} ${!userDetails?.MobileNumber ? "bg-gray-100 cursor-not-allowed" : ""
                              }`}
                            placeholder="Bank Name"
                            value={bankName}
                            onChange={(e) => setBankName(e.target.value)}
                            disabled={!userDetails?.MobileNumber}
                          />

                          <label htmlFor="ifscCode" className="block mt-3 text-sm text-zinc-400">
                            IFSC Code
                          </label>
                          <input
                            type="text"
                            id="ifscCode"
                            className={`${inputBoxClass} ${!userDetails?.MobileNumber ? "bg-gray-100 cursor-not-allowed" : ""
                              }`}
                            placeholder="IFSC Code"
                            value={ifscCode}
                            onChange={(e) => setIfscCode(e.target.value)}
                            disabled={!userDetails?.MobileNumber}
                          />

                          <label htmlFor="nickName" className="block mt-3 text-sm text-zinc-400">
                            Nick Name
                          </label>
                          <input
                            type="text"
                            id="nickName"
                            className={`${inputBoxClass} ${!userDetails?.MobileNumber ? "bg-gray-100 cursor-not-allowed" : ""}`}
                            placeholder="Enter your name"
                            value={nickName}
                            onChange={(e) => {
                              const alphanumericValue = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
                              setNickName(alphanumericValue);
                            }}
                            disabled={!userDetails?.MobileNumber}
                          />
                          {
                            nickName != null && nickName?.length < 3 && (
                              <span className="mt-1 text-sm text-white-500">
                                The nickname should be AlphaNumeric only with minimum length of 3
                              </span>
                            )
                          }
                          <button
                            type="submit"
                            className={`p-2.5 mt-4 w-full text-sm text-white whitespace-nowrap bg-border-color rounded-lg border border-border-color border-solid leading-[1.4rem] ${loading || !allFieldsFilled() ? "opacity-50" : ""
                              }`}
                            disabled={loading || !allFieldsFilled()}
                          >
                            {userDetails?.MobileNumber
                              ? loading
                                ? "Submitting..."
                                : "Submit"
                              : "Add Mobile Number"}
                          </button>
                        </form>
                      </>
                    </div>
                  </div>
                </div>
              </Dialog>
            )
          }

          {
            openAmForm && (
              <Dialog
                fullScreen={false}
                open={openAmForm}
                onClose={() => { setOpenAmForm(false); setAmount(0) }}
                PaperProps={{
                  style: {
                    position: 'absolute',
                    top: '2rem'
                  }
                }}
              >
                <div className="withdrawdialog-bg bg-inputbox-color relative p-4">
                  <CloseIcon
                    className="absolute top-3 right-3 text-white text-2xl font-semibold"
                    onClick={() => { setOpenAmForm(false); setAmount(0) }}
                  />
                  <div>
                    <div>
                      Enter WithDrawal Amount
                    </div>
                    <div>
                      <>
                        <form className="flex flex-col w-full" onSubmit={handleSubmit}>
                          <label
                            htmlFor="accountHolderName"
                            className="block mt-3 text-sm text-zinc-400"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            id="accountHolderName"
                            className={`${inputBoxClass} ${!userDetails?.MobileNumber ? "bg-gray-100 cursor-not-allowed" : ""
                              }`}
                            placeholder="Account Holder Name"
                            value={bankAccountSelected?.name}
                            onChange={(e) => setAccountHolderName(e.target.value)}
                            disabled
                          />

                          <label htmlFor="amount" className="block mt-3 text-sm text-zinc-400">
                            Amount
                          </label>
                          <input
                            type="number"
                            id="amount"
                            className={`${inputBoxClass} ${!userDetails?.MobileNumber ? "bg-gray-100 cursor-not-allowed" : ""
                              }`}
                            placeholder="Enter your amount"
                            value={amount}
                            onChange={(e) => {
                              const value = e.target.value === "" ? 0 : parseFloat(e.target.value);
                              setAmount(value);
                            }}
                            disabled={!userDetails?.MobileNumber}
                          />
                          {amount != null && (amount < 200) && (
                            <span className="mt-1 text-sm text-orange-500">
                              Amount must be greater than or equal to 200
                            </span>
                          )}

                          <button
                            type="submit"
                            className={`p-2.5 mt-4 w-full text-sm text-white whitespace-nowrap bg-border-color rounded-lg border border-border-color border-solid leading-[1.4rem] ${loading || !(amount && amount > 199) ? "opacity-50" : ""
                              }`}
                            disabled={loading || !(amount && amount > 199)}
                          >
                            {userDetails?.MobileNumber
                              ? loading
                                ? "Submitting..."
                                : "Submit"
                              : "Add Mobile Number"}
                          </button>
                        </form>
                      </>
                    </div>
                  </div>
                </div>
              </Dialog>
            )
          }

          <div className="h-[180px] p-4">
          </div>
        </>
      )
    },
    {
      label: "Crypto",
      content: (
        <>
          <label
            htmlFor="accountHolderName"
            className="block mt-3 text-sm text-zinc-400"
          >
            Select Currency
          </label>
          <div className="relative w-full h-[30px]">
            <button
              onClick={toggleDropdown}
              className="flex items-center mt-2 h-full justify-between px-3 py-2 w-full text-xs font-medium text-white whitespace-nowrap rounded-lg border border-solid bg-inputbox-color border-border-color"
            >
              <span className="truncate">
                {selectedCurrency?.crypto_currency
                  ? `${selectedCurrency?.crypto_currency} (${selectedCurrency?.blockchain})`
                  : ""}
              </span>
              <img
                loading="lazy"
                src={DownArrow}
                alt=""
                className="shrink-0 ml-2 w-2.5 aspect-[1.67]"
              />
            </button>

            {isOpen && (
              <ul className="absolute z-10 w-full left-0 top-full mt-1 bg-inputbox-color border border-slate-800 rounded-lg shadow-lg">
                {currList?.map((curr: any) => (
                  <li
                    key={curr}
                    onClick={() => handleCurrencySelect(curr)}
                    className="px-3 py-2 text-xs text-white hover:bg-zinc-700 cursor-pointer"
                  >
                    {`${curr?.crypto_currency} (${curr?.blockchain})`}
                  </li>
                ))}
              </ul>
            )}
          </div>

          <label htmlFor="amount" className="block mt-3 text-sm text-zinc-400">
            Amount
          </label>
          <input
            type="number"
            id="amount"
            className={inputBoxClass}
            placeholder="Enter your amount"
            value={cryptoAmount}
            onChange={(e) => {
              const value =
                e.target.value === "" ? 0 : parseFloat(e.target.value);
              setCryptoAmount(value);
            }}
          />

          <label htmlFor="walletAddress" className="block mt-3 text-sm text-zinc-400">
            Enter your Wallet Address
          </label>
          <input
            type="text"
            id="walletAddress"
            className={inputBoxClass}
            placeholder="Enter your Wallet Address"
            value={walletAddress}
            onChange={(e) => setWalletAddress(e.target.value)}
          />

          <button
            type="submit"
            className={`p-2.5 mt-4 w-full text-sm text-white whitespace-nowrap bg-border-color rounded-lg border border-border-color border-solid leading-[1.4rem] ${loading || !allCryptoFieldsFilled() ? "opacity-50" : ""
              }`}
            disabled={loading || !allCryptoFieldsFilled()}
            onClick={() => cryptoWithdrawal()}
          >
            {userDetails?.MobileNumber ? (loading ? "Submitting..." : "Submit") : "Add Mobile Number"}
          </button>
          <div className="h-[200px] p-4">
          </div>
        </>
      )
    },

  ]
  return (
    <>
      <div className="deposit-tabs">
        <div className="wallet-nav">
          <div className="nav nav-pills" role="tablist">
            {tabs.map((tab) => (
              <div className="nav-item" key={tab.label}>
                <a
                  role="tab"
                  className={`nav-link ${activeCurType === tab.label ? "active" : ""}`}
                  onClick={() => handleTabClick(tab.label)}
                >
                  {tab.label}
                </a>
              </div>
            ))}
          </div>

          <div className="tab-content">
            {tabs.map((tab) => (
              <div
                key={tab.label}
                className={`tab-pane fade ${activeCurType === tab.label ? "show active" : ""
                  }`}
                id={`tabs-tabpane-${tab.label}`}
                role="tabpanel"
              >
                {activeCurType === tab.label && tab.content}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    userDetails: state.userDetails?.userDetails,
  };
};


export default connect(mapStateToProps)(WithdrawForm);
