import React, { useEffect, useState } from "react";
import Casino from "./../../assets/images/casino.svg";
import Sports from "./../../assets/images/sports.svg";
import FullSite from "./../../assets/images/full-site.svg";
import WalletSvg from "./../../assets/images/wallet.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Home from "../../assets/images/stikelogo.svg";

type FooterItem = {
  icon: string;
  label: string;
  path: string;
};

const footerItems: FooterItem[] = [
  {
    icon: Casino,
    label: "Casino",
    path: "casino",
  },
  {
    icon: Sports,
    label: "Sports",
    path: "sports",
  },
  {
    icon: Home,
    label: "Home",
    path: "home",
  },
  {
    icon: FullSite,
    label: "Site",
    path: "site",
  },
  {
    icon: WalletSvg,
    label: "Wallet",
    path: "wallet",
  },
];

const Footer: React.FC = () => {
  const [activeTab, setActiveTab] = useState("Home");
  let navigate = useNavigate();
  const location = useLocation();

  const handleItemClick = (tab: any) => {
    setActiveTab(tab?.label);
    navigate("/" + tab.path);
  };

  useEffect(() => {
    const currentItem = footerItems.find(
      (item) => location.pathname.includes(item.path)
    );

    if (currentItem) {
      setActiveTab(currentItem?.label);
    }
  }, [])


  return (
    // <div className="flex justify-center">
      <footer
     className={`fixed bottom-0 left-1/2 transform -translate-x-1/2 w-[95%] text-sm max-w-[25rem] font-medium rounded-2xl border-b-0 border border-border-color text-zinc-400 flex flex-col px-5 py-4 justify-center z-50`}
        style={{background: "linear-gradient(to top, rgba(5,19,20), rgba(5,19,20,0.8))"}}
      >
        <nav className="flex gap-5 justify-between">
          {footerItems.map((item, index) => (
            <div
              key={index}
              className={`flex flex-col ${item.label === "Full site"
                  ? "text-center"
                  : "text-center whitespace-nowrap"
                } ${item.label === activeTab ? "text-border-color" : "text-text-color"
                }`}
              onClick={() => handleItemClick(item)}
              style={{ cursor: "pointer" }}
            >
              <img
                loading="lazy"
                src={item.icon}
                alt=""
                className={`self-center ${item.label === "Site"
                    ? "aspect-[1.45] w-[26px]"
                    : item.label === "Wallet"
                      ? "w-5 rounded-lg aspect-[1.11]"
                      : item.label === "Home"
                        ? 'w-[48px]'
                        : "aspect-square w-[18px]"
                  }`}
                style={{
                  filter:
                    item.label === activeTab
                      ? 'var(--filter-color)'
                      : "brightness(0) saturate(100%) invert(69%) sepia(0%) saturate(923%) hue-rotate(219deg) brightness(101%) contrast(99%)",
                  height: item.label === "Home" ? '22px' : '',
                }}
              />
              <div className={`${item.label === "Home" ? "mt-1" : "mt-2"}`}>{item.label}</div>
            </div>
          ))}
        </nav>
      </footer>
    // </div>
  );
};

export default Footer;
