import React, { useEffect, useState } from "react";
import DownArrow from "./../../assets/images/down-arrow.svg";
import ProfileImg from "./../../assets/images/profileImage.png";
import { NavLink, useLocation } from 'react-router-dom'; // Import Link from react-router-dom
import Dialog from "@mui/material/Dialog";
import "./WalletInfo.scss";
import API from "../../services";
import { toast } from "react-toastify";
import { RootState } from "../../models/RootState";
import { connect } from "react-redux";
import { setUserDetails, setWallets, setActiveWallet, setUserThumbnail } from "../../store/authReducer";
import { UserDetails } from "../../models/UserDetails";
import Logo from "../../assets/images/stikelogo.svg";
import { themes } from "./MyProfile/MyProfile";
import { checkTelegramWebApp, loadColorConfig, telegramWebApp } from "../../constants/common";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SupportIcon from "../../assets/images/support.svg";

type WalletInfoProps = {
  balance: string;
  setUserDetails: (data: any) => void;
  setWallets: (data: any) => void;
  userDetails: UserDetails;
  setActiveWallet: (data: any) => void;
  wallets: any;
  activeWallet: any;
  setUserThumbnail: (data: any) => void;
  userThumbnail: any;
};

interface TelegramUser {
  id: number;
  first_name: string;
  last_name?: string;
  username?: string;
  language_code?: string;
}

interface TelegramInitDataUnsafe {
  query_id?: string;
  user?: TelegramUser;
  auth_date?: number;
  hash?: string;
}

const WalletInfo: React.FC<WalletInfoProps> = ({ balance, setUserDetails, setWallets, userDetails, setActiveWallet, wallets, activeWallet, userThumbnail, setUserThumbnail }) => {

  const [showBalInfo, setShowBalInfo] = useState<any>(false);
  const [userAllBalance, setUserAllBalance] = useState<any>(null);
  const [selectedBalance, setSelectedBalance] = useState<any>();
  const [nonZeroBalance, setNonZeroBalance] = useState<any>();
  const [hideZeroBalances, setHideZeroBalances] = useState(true);
  const [user, setUser] = useState<TelegramUser | null>(null);
  const location = useLocation();
  
  const path = location?.pathname




  const getUserBalance = async () => {

    let response: any;
    try {
      response = await API.post(`/webapp/get-user-balance`, {
        userKey: sessionStorage.getItem("userKey"),
      });
    } catch (e) {
      return;
    }

    if (response.data.status !== "RS_OK") {
      toast.error(response.data.errDescription);
    }

    if (response && response.data.wallets) {
      setUserAllBalance(response.data?.wallets);
      setNonZeroBalance(response.data.wallets);
      setWallets(response.data?.wallets);
      setActiveWallet(response?.data?.activeWallet)
      const matchingWallet = response.data?.wallets.find(
        (wallet: any) => wallet?.walletName === response.data?.activeWallet
      );
      setSelectedBalance(matchingWallet);
    }
  };

  const changeActiveWallet = async (wallet: any) => {
    let response: any;
    try {
      response = await API.post(`/users/change-active-wallet`, {
        userKey: sessionStorage.getItem("userKey"),
        activeWallet: wallet?.walletName,
      });
    } catch (e) {
      return;
    }
    if (response.data?.status !== "RS_OK") {
      toast.error(response.data?.errorDescription);
    } else {
      getUserBalance();
    }
  }

  const getRefId = async () => {
    try {
      const userKey = sessionStorage.getItem("userKey");
      if (!userKey) {
        console.error("User ID not found in session storage");
        return;
      }
      const response = await API.post(`/users/get-user-details`, {
        userKey: userKey,
      });

      if (!response || response.data.status !== "RS_OK") {
        console.error("Error retrieving user details or invalid status");
        return;
      }

      const fetchedUserDetails: UserDetails = response.data?.userDetails;

      const validThemes = ['blue', 'purple', 'green', 'yellow', 'orange'] as const;
      type ThemeType = typeof validThemes[number];

      if (fetchedUserDetails) {
        setUserDetails(fetchedUserDetails);
        setUserThumbnail(fetchedUserDetails?.Thumbnail);
        if (validThemes.includes(fetchedUserDetails.Theme as ThemeType)) {
          setTheme(fetchedUserDetails.Theme as ThemeType);
        } else {
          setTheme('blue');
        }
      } else {
        console.error("User details not found in response");
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const setTheme = (themeName: keyof typeof themes) => {
    const selectedTheme = themes[themeName];

    if (selectedTheme) {
      loadColorConfig(selectedTheme)
    } else {
      console.error('Theme not found');
    }
  };

  const zeroBalance = () => {
    const filteredValue = userAllBalance?.filter((item: any) => item.balance > 0);
    if (hideZeroBalances) {
      setNonZeroBalance(filteredValue);
    } else {
      setNonZeroBalance(userAllBalance);
    }
    setHideZeroBalances(!hideZeroBalances)
  }

  const onCurrencyClick = (wallet: any) => {
    changeActiveWallet(wallet);
    setSelectedBalance(wallet);
    setShowBalInfo(false);
  }


  useEffect(() => {
    getUserBalance();
    getRefId();
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      getUserBalance();
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const matchingWallet = wallets?.find(
      (wallet: any) => wallet?.walletName === activeWallet
    );
    setSelectedBalance(matchingWallet);
  }, [wallets])




  const dummyApi = async (dum: any) => {

    let response: any;
    try {
      response = await API.post(`/debug/debug-info`, {
        info: dum
      });

      console.log("resp", response.data)
    } catch (e) {
      return;
    }


  };

  return (
    <>
      <div className="fixed flex flex-col justify-center px-4 py-3 w-full text-sm font-medium text-white whitespace-nowrap bg-header-color z-50 wallet-info">
        <div className="flex gap-5 justify-between w-full">
          <div className="flex w-[27%] justify-between">
            <img
              loading="lazy"
              src={Logo}
              alt=""
              className="shrink-0 my-auto rounded-none w-14"
            />
          </div>
          {/* <SupportAgentIcon /> */}
          <div className="flex gap-3 justify-between w-full">
            <NavLink to="/customersupport">
              <img src={SupportIcon} alt=""
                className="w-9 aspect-square cursor-pointer"
                style={{ filter: path === "/customersupport" ? 'var(--filter-color)' : "invert(100%) sepia(100%) saturate(0%) hue-rotate(139deg) brightness(110%) contrast(101%)" }}
              />
            </NavLink>
            <div className="flex flex-col justify-center rounded-lg w-[60%]">
              <div className="flex flex-col justify-center px-3 py-2 w-full rounded-lg border border-solid bg-inputbox-color border-slate-800"
              >
                <div className="flex gap-1.5">
                  <img
                    loading="lazy"
                    src={selectedBalance?.walletIcon}
                    alt=""
                    className="shrink-0 my-auto aspect-square w-[18px]"
                  />
                  <div className="flex gap-5 justify-between text-xs"
                    onClick={() => setShowBalInfo(true)}
                  >
                    <div className="flex">{selectedBalance?.walletTitle}: {selectedBalance?.balance ? selectedBalance?.balance : 0}
                      {/* <div className="text-border-color ml-1">
                        Exp: {`${selectedBalance?.exposure ? selectedBalance?.exposure : 0}`}
                      </div> */}
                    </div>
                    <img
                      loading="lazy"
                      src={DownArrow}
                      alt=""
                      className="shrink-0 my-auto w-2.5 aspect-[1.67]"
                    />
                  </div>
                </div>
              </div>
            </div>
            <NavLink to="/profile" className="shrink-0">
              <img
                loading="lazy"
                src={userThumbnail === "" ? ProfileImg : userThumbnail}
                alt="Profile"
                className="w-9 rounded-full aspect-square cursor-pointer"
              />
            </NavLink>
          </div>
        </div>
      </div>

      {
        showBalInfo && (
          <Dialog
            fullScreen={false}
            open={showBalInfo}
            onClose={() => setShowBalInfo(false)}
            PaperProps={{
              style: {
                position: 'absolute',
                top: '2rem'
              }
            }}
          >
            <div className="dialog-bg bg-inputbox-color">
              <div>
                {
                  nonZeroBalance?.map((wallet: any) => {
                    return (
                      <>
                        <div className={`main-section ${wallet?.walletTitle === selectedBalance?.walletTitle ? "selected-bal-bg" : ""}`}
                          onClick={() => onCurrencyClick(wallet)}
                        >
                          <div style={{ display: "flex" }}>
                            <div>
                              <img
                                src={wallet?.walletIcon}
                                alt="icon"
                                style={{ height: '1rem', marginRight: '5px' }}
                              />
                            </div>
                            <div>
                              {wallet?.walletTitle}
                            </div>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'space-between', width: '65%' }}>
                            <div>
                              bal: {wallet?.balance}
                            </div>
                            <div>
                              exp: {wallet?.exposure}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                }
              </div>
              <div className="sec-divider"></div>
              <div className="text-xs mb-4"><b>Note: </b> PlayChips are provided to encourage you to play.
              Only The profit from the PlayChips would be converted to points and The bets placed through the
              PlayChips would not come under Wager.
              </div>
              <div className="toggle-section">
                <div>Hide 0 Balances</div>
                <div className="view-layer">
                  <label className="switch">
                    <input
                      type="checkbox"
                      onClick={() => zeroBalance()}
                      checked={!hideZeroBalances}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
          </Dialog>
        )
      }
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    userDetails: state.userDetails?.userDetails,
    wallets: state.userDetails?.wallets,
    activeWallet: state.userDetails?.activeWallet,
    userThumbnail: state.userDetails?.Thumbnail,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    setUserDetails: (data: any) => {
      dispatch(setUserDetails(data));
    },
    setWallets: (data: any) => {
      dispatch(setWallets(data));
    },
    setActiveWallet: (data: any) => {
      dispatch(setActiveWallet(data));
    },
    setUserThumbnail: (data: any) => {
      dispatch(setUserThumbnail(data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WalletInfo);
